

import AuthEndpoints from '../../../config/endpoints/categories';
import useRequest from '../index';


const { addcategory,updatecategoryUrl,getall } = AuthEndpoints;

const useCategory = (data) => {


    /* LOCAL STATES */
    

    /* HOOKS */
    const {get,post} = useRequest()

   

    const getallCategories = async () => get(getall)
    const addnewCategory = async (data) => post(addcategory,data)
    const updatecategory = async (data) => post(updatecategoryUrl,data)


   

    return { getallCategories,addnewCategory,updatecategory }

}

export default useCategory