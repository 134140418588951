import styled from 'styled-components'


export const LoginWarapper = styled.div`
    width: 100%;
    height: 100vh;
    display:flex;
    justify-content : center;
    align-items : center;
`

export const Container = styled.div`
    width:40%;
    height:400px;
    border : 1px solid #d0d7de;
    display:flex;
    justify-content : center;
    align-items : center;

    Form{
       
    }
   
`