import React, {useState, useEffect, useRef} from 'react';
import {
    Modal,
    Form,
    Input,
    Button,
    Checkbox,
    message
} from 'antd';
// import { Editor } from "react-draft-wysiwyg";

import useStores from '../../../../hooks/api/stores/useStores';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {Editor} from '@tinymce/tinymce-react';

const {TextArea} = Input;


const UIModal = ({
    modal2Visible,
    setModal2Visible,
    modalData,
    setTableData,
    setOriginalData
}) => {
    const [storeName, setStoreName] = useState(null)
    const [storeCode, setStoreCode] = useState(null)
    const [storeScore, setStoreScore] = useState(null)
    const [seoTitle, setSeoTitle] = useState(null)
    const [seoDescription, setSeoDescription] = useState(null)
    const [description, setDescription] = useState(null)
    const [relatedDescription, setRelatedDescription] = useState(null)
    const [affliateUrl, setAffliateUrl] = useState(null)
    const [officialUrl, setOfficialUrl] = useState(null)
    const [redirectUrl, setRedirectUrl] = useState(null)
    const [scriptTag, setScriptTag] = useState(null)
    const [h1, setH1] = useState(null)
    const [bgcolor, setBgcolor] = useState(null)
    const [imgUrl, setImgUrl] = useState(null)
    const [mobileImgUrl, setmobileImgUrl] = useState(null)

    const {updateStore, getStores, addStore} = useStores()

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };


    const handleSubmit = async () => { // e.preventDefault();

        // if (editorRef.current) {
        //     console.log(editorRef.current.getContent());
        // }

        let data = {
            s_display_name: storeName,
            s_code: storeCode,
            s_description_text: editorRef.current && editorRef.current.getContent(),
            s_related_description: relatedDescription,
            s_seo_description: seoDescription,
            s_seo_title: seoTitle,
            s_h1: h1,
            s_affliate_url: affliateUrl,
            s_official_url: officialUrl,
            s_redirect_url: redirectUrl,
            s_image_url: imgUrl,
            s_last_update_on: new Date().toISOString().slice(0, 10),
            s_score: storeScore,
            script_tag: scriptTag,
            s_bg_color: bgcolor,
            s_mobile_img_url: mobileImgUrl

        }

        let result;
        if (!modalData.s_display_name) {
            result = await addStore(JSON.stringify(data))

        } else {
            result = await updateStore(JSON.stringify(data))
        }


        setModal2Visible(false)
        let newData = await getStores();
        setTableData(newData.data)
        setOriginalData(newData.data)
        if (result.success) {
            message.success("Saved!!");
        }

    }

    const onEditorStateChange = (editorState) => {
        this.setState({editorState});
    };

    function process(str) {
        var div = document.createElement('div');
        div.innerHTML = str.trim();

        return format(div, 0).innerHTML;
    }

    function format(node, level) {
        var indentBefore = new Array(level++ + 1).join('  '),
            indentAfter = new Array(level - 1).join('  '),
            textNode;

        for (var i = 0; i < node.children.length; i++) {
            textNode = document.createTextNode('\n' + indentBefore);
            node.insertBefore(textNode, node.children[i]);

            format(node.children[i], level);

            if (node.lastElementChild == node.children[i]) {
                textNode = document.createTextNode('\n' + indentAfter);
                node.appendChild(textNode);
            }
        }

        return node;
    }

    useEffect(() => {
        setStoreName(modalData.s_display_name && modalData.s_display_name);
        setStoreCode(modalData.s_code ? modalData.s_code : "");
        setStoreScore(modalData.s_score ? modalData.s_score : "");
        setSeoTitle(modalData.s_seo_title ? modalData.s_seo_title : "");
        setSeoDescription(modalData.s_seo_description ? modalData.s_seo_description : "");
        setDescription(modalData.s_description_text ? process(modalData.s_description_text) : "");
        setRelatedDescription(modalData.s_related_description ? modalData.s_related_description : "");
        setH1(modalData.s_h1 ? modalData.s_h1 : "")
        setAffliateUrl(modalData.s_affliate_url ? modalData.s_affliate_url : "");
        setOfficialUrl(modalData.s_official_url ? modalData.s_official_url : "");
        setRedirectUrl(modalData.s_redirect_url == false || modalData.s_redirect_url == true ? modalData.s_redirect_url : false);
        setImgUrl(modalData.s_image_url ? modalData.s_image_url : "")
        setScriptTag(modalData.script_tag ? modalData.script_tag : "")
        setBgcolor(modalData.s_bg_color ? modalData.s_bg_color : null)
        setmobileImgUrl(modalData.s_mobile_img_url ? modalData.s_mobile_img_url : null)
    }, [modalData])

    return <Modal title={
            modalData.s_display_name ? `${
                modalData.s_display_name
            } Store Data` : "Add New Store"
        }
        centered
        visible={modal2Visible}
        destroyOnClose={true}
        footer={null}
        onOk={
            () => setModal2Visible(false)
        }
        onCancel={
            () => setModal2Visible(false)
        }
        width={"80%"}>

        <br/>
        <Form name="basic"
            labelCol={
                {span: 4}
            }
            wrapperCol={
                {span: 20}
            }
            onFinish={handleSubmit}
            autoComplete="off"
            fields={
                [
                    {
                        name: ["Store Display Name"],
                        value: storeName
                    },
                    {
                        name: ["Store Code"],
                        value: storeCode
                    },
                    {
                        name: ["Store Score"],
                        value: storeScore
                    },
                    {
                        name: ["SEO Title"],
                        value: seoTitle
                    }, {
                        name: ["SEO Description"],
                        value: seoDescription
                    }, {
                        name: ["Description"],
                        value: description
                    }, {
                        name: ["Related Description"],
                        value: relatedDescription
                    }, {
                        name: ["Affliate Url"],
                        value: affliateUrl
                    }, {
                        name: ["Official Url"],
                        value: officialUrl
                    }, {
                        name: ["Redirect Url"],
                        value: redirectUrl
                    }, {
                        name: ["Image Url"],
                        value: imgUrl
                    }, {
                        name: ["Mobile Image Url"],
                        value: mobileImgUrl
                    }, {
                        name: ["h1"],
                        value: h1
                    }, {
                        name: ["scripttag"],
                        value: scriptTag
                    }, {
                        name: ["bgcolor"],
                        value: bgcolor
                    },
                ]
        }>
            <div style={
                {
                    display: "flex",
                    width: "100%"
                }
            }>
                <div style={
                    {width: "50%"}
                }>
                    <Form.Item label="Store Display Name" name="Store Display Name"
                        rules={
                            [{
                                    required: true,
                                    message: 'Please enter Store Display Name!'
                                },]
                    }>
                        <Input name="sdn"
                            value={storeName}
                            onChange={
                                (e) => {
                                    setStoreName(e.target.value)
                                }
                            }/>
                    </Form.Item>

                <Form.Item label="Store Code" name="Store Code"
                    rules={
                        [{
                                required: true,
                                message: 'Please enter Store Code!'
                            },]
                }>
                    <Input value={storeCode}
                        onChange={
                            (e) => {
                                setStoreCode(e.target.value)
                            }
                        }/>
                </Form.Item>

            <Form.Item name="Store Score" label="Store Score"
                rules={
                    [{
                            required: true,
                            message: 'Please enter Store Score!'
                        },]
            }>
                <Input type="number"
                    value={storeScore}
                    onChange={
                        (e) => {
                            setStoreScore(e.target.value)
                        }
                    }/>
            </Form.Item>

        <Form.Item label="SEO Title" name="SEO Title">
            <Input value={seoTitle}
                onChange={
                    (e) => {
                        setSeoTitle(e.target.value)
                    }
                }/>
        </Form.Item>

    <Form.Item label="SEO Description" name="SEO Description">
        <Input value={seoDescription}
            onChange={
                (e) => {
                    setSeoDescription(e.target.value)
                }
            }/>
    </Form.Item>


<Form.Item label="Related Description" name="Related Description">
    <Input value={relatedDescription}
        onChange={
            (e) => {
                setRelatedDescription(e.target.value)
            }
        }/>
</Form.Item><Form.Item label="Image Url" name="Image Url"
    rules={
        [{
                required: true,
                message: 'Please enter Image Url!'
            },]
}>
<Input value={imgUrl}
    onChange={
        (e) => {
            setImgUrl(e.target.value)
        }
    }/></Form.Item><Form.Item label="Affliate Url" name="Affliate Url"><Input value={affliateUrl}
    onChange={
        (e) => {
            setAffliateUrl(e.target.value)
        }
    }/></Form.Item><Form.Item label="Official Url" name="Official Url"
    rules={
        [{
                required: true,
                message: 'Please enter Official Url!'
            },]
}><Input value={officialUrl}
    onChange={
        (e) => {
            setOfficialUrl(e.target.value)
        }
    }/></Form.Item><Form.Item label="Affliate Url" name="Redirect Url"><Checkbox checked={redirectUrl}
    disabled={
        affliateUrl && affliateUrl != "NULL" ? false : true
    }
    onChange={
        (e) => {
            setRedirectUrl(e.target.checked)
        }
}>
    {/* Affliate Url */} </Checkbox></Form.Item><Form.Item label="Mobile Image Url" name="Mobile Image Url"
    rules={
        [{ // required: true,
                message: 'Please enter mobile Image Url!'
            },]
}><Input value={imgUrl}
    onChange={
        (e) => {
            setmobileImgUrl(e.target.value)
        }
    }/></Form.Item></div><div style={
    {width: "50%"}
}><Form.Item label="h1" name="h1"
    rules={
        [{
                required: true,
                message: 'Please enter h1!'
            },]
}><Input value={h1}
    onChange={
        (e) => {
            setH1(e.target.value)
        }
    }/></Form.Item><Form.Item label="bgcolor" name="bgcolor"><Input value={bgcolor}
    onChange={
        (e) => {
            setBgcolor(e.target.value)
        }
    }/></Form.Item><Form.Item label="Add script" name="scripttag"><TextArea rows={4}
    value={scriptTag}
    onChange={
        (e) => {
            setScriptTag(e.target.value)
        }
    }/></Form.Item><Form.Item label="Description" name="Description">
    {/* <Editor
                            editorState={description}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={on
                                EditorStateChange}
                        /> */}<Editor apiKey='fop6unfmbtk0xdx7dqke7590zhyojg93kis34uvej1ucot9b'
    onInit={
        (evt, editor) => editorRef.current = editor
    }
    initialValue={description}
    init={
        {
            height: 500,
            menubar: true,
            plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'help',
                'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' + 'bold italic backcolor | alignleft aligncenter ' + 'alignright alignjustify | bullist numlist outdent indent | ' + 'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }
    }/>
    {/* <button onClick={log}>Log editor content</button> */}
    {/* <TextArea style={{ height: "400px", fontSize: "12px" }} value={description} onChange={(e) => { setDescription(e.target.value) }} /> */} </Form.Item></div> </div> <Form.Item wrapperCol={
    {
        offset: 12,
        span: 16
    }
}><Button type="primary" htmlType="submit"
    /*onClick={(e)=>{e.preventDefault()}}*/
>Submit</Button> </Form.Item></Form></Modal>

}

export default UIModal;
