import React, { Component, useEffect, useState } from 'react'
import LoginPage from './Pages/Login';
import HomePage from './Pages/Home';
import Stores from './Pages/Stores';
import DesktopBanners from './Pages/DesktopBanners';
import MobileBanners from './Pages/MobileBanners';
import Coupons from './Pages/Coupons';
import Deals from './Pages/Deals';
import Categories from './Pages/Categories';
import Banks from "./Pages/Banks";
import ImageUpload from "./Pages/ImageUpload"
import Blogs from "./Pages/Blogs"
import Notification from "./Pages/Notification"
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'
import LayoutCover from "./Layout";
import PrivateRoute from './HOC/PrivateRoute';
import PublicRoute from "./HOC/PublicRoute";


const UI = (props) => {


  const [refreshToken, setRefreshToken] = useState()



  const localStorageUpdated = () => {
    if (!localStorage.getItem('token')) {
      setRefreshToken(false)
    }
    else if (!this.state.status) {
      setRefreshToken(true)
    }
  }


  useEffect(async () => {

    if (typeof window !== 'undefined') {

    }
  }, [])

  return <Router >
    <LayoutCover>
      <Routes>
        <Route exact path="/" element={<PublicRoute ><LoginPage /></PublicRoute>} />
        <Route exact path="/insight" element={<PrivateRoute ><HomePage /></PrivateRoute>} />
        <Route exact path="/desktopbanners" element={<PrivateRoute ><DesktopBanners /></PrivateRoute>} />
        <Route exact path="/mobilebanners" element={<PrivateRoute ><MobileBanners /></PrivateRoute>} />
        <Route exact path="/stores" element={<PrivateRoute ><Stores /></PrivateRoute>} />
        <Route exact path="/uploadimage" element={<PrivateRoute ><ImageUpload /></PrivateRoute>} />
        <Route exact path="/categories" element={<PrivateRoute ><Categories /></PrivateRoute>} />
        <Route exact path="/banks" element={<PrivateRoute><Banks /></PrivateRoute>}/>
        <Route exact path="/blogs" element={<PrivateRoute ><Blogs /></PrivateRoute>} />
        <Route exact path="/coupons" element={<Coupons />} />
        <Route exact path="/deals" element={<Deals />} />

        <Route exact path="/notification" element={<Notification />} />

      </Routes>
    </LayoutCover>
  </Router>


}

export default UI;


// const mapStateToProps = ({ auth }) => ({ auth });
// export default connect(mapStateToProps)(UI);
