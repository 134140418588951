import React, { Component, useEffect,useState } from 'react'
import { Route,Navigate,useNavigate } from 'react-router-dom'


const PublicRoute = ({ children }) =>{
    
    let refreshToken = localStorage.getItem('token') ? true : false
    
    return refreshToken ? <Navigate to="/insight" /> : children
}

export default PublicRoute;