import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, AutoComplete, Select, Button, Checkbox, message } from 'antd';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useCoupons from '../../../../hooks/api/coupons/useCoupons';
import moment from 'moment';

const { Option } = Select;

const CouponModel = ({ modal2Visible, setModal2Visible, modalData, setTableData, searchData }) => {

    const [storeCode, setStoreCode] = useState(modalData.c_store_code ? modalData.c_store_code : "")
    const [couponCode, setCouponCode] = useState(modalData.coupon_code ? modalData.coupon_code : "")
    const [expireDate, setExpireDate] = useState(modalData.c_expire_date ? moment(modalData.c_expire_date).toDate() : new Date)
    const [details, setDetails] = useState(modalData.c_details ? modalData.c_details : "")
    const [shortTitle, setShortTitle] = useState(modalData.c_short_title ? modalData.c_short_title : "")
    const [verified, setVerified] = useState(modalData.c_is_coupon_verified ? modalData.c_is_coupon_verified : true)
    const [mainText, setMainText] = useState(modalData.c_main_text ? modalData.c_main_text : "")
    const [newUser, setNewUser] = useState(modalData.c_new_user ? modalData.c_new_user : false)
    const [c_exclusive_coupon, setExclusiveCoupon] = useState(modalData.c_exclusive_coupon ? modalData.c_exclusive_coupon : false)
    const [displayOrder, setDisplayOrder] = useState(modalData.c_display_order ? modalData.c_display_order : 0)
    const [categoryList, setCategoryList] = useState([])
    const [bankList, setBankList] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedBank, setSelectedBank] = useState([])
    const [defaultCategory, setDefaultCategory] = useState(modalData.defaultValue ? modalData.defaultValue : [])
    const [defaultBank, setDefaultBank] = useState(modalData.defaultBanksValue ? modalData.defaultBanksValue : [])
    const [lastUpdate, setLastUpdate] = useState(modalData.c_last_update_on ? modalData.c_last_update_on : "")

    const [search, setSearch] = useState(searchData ? searchData : [])

    const { getallCategories, getallBanks, getStoreCoupons, addCoupon, updateCoupon } = useCoupons()

    const onSearch = (e) => {
        let input = e.toLowerCase();
        setStoreCode(input)
        if (input.length > 0) {
            let filteredStoresData = searchData.filter((row, index) => {
                if ((row.value).toLowerCase().includes(input)) {
                    return row
                }
            })
            setSearch(filteredStoresData);
        } else {
            setSearch(searchData);
        }
    }

    const children1 = [];

    const children2 = [];

    function handleCategoryChange(value) {
        setSelectedCategory(value)
    }

    const handleBankChange = (value) => {
        setSelectedBank(value)
    }

    const onSelect = (e) => {
        setStoreCode(e)
    }

    useEffect(async () => {
        let categories = await getallCategories()
        categories.data && categories.data.forEach((item, i) => {
            children1.push(<Option key={item.category_code}>{item.category_code}</Option>);
        })
        setCategoryList(children1)
        setSelectedCategory(modalData.defaultValue ? modalData.defaultValue : [])

        let banks = await getallBanks()
        console.log(banks)
        banks.data && banks.data.forEach((item, i) => {
            children2.push(<Option key={item.bank_code}>{item.bank_code}</Option>);
        })
        setBankList(children2)
        setSelectedBank(modalData.defaultBanksValue ? modalData.defaultBanksValue : [])
    }, [])

    const handleSubmit = async (e) => {
      
        let data = {
            c_store_code: storeCode,
            coupon_code: couponCode,
            c_expire_date: moment(expireDate).format("YYYY-MM-DD"),
            c_details: details,
            c_short_title: shortTitle,
            c_is_coupon_verified: verified,
            c_main_text: mainText,
            c_new_user: newUser,
            c_exclusive_coupon: c_exclusive_coupon,
            c_display_order: displayOrder,
            categoryList: selectedCategory,
            bankList: selectedBank,
            c_last_update_on: new Date().toISOString().slice(0, 10)
        }

        let couponResult;
        if(selectedCategory.length == 0){
            message.error("Please enter a Category")
        }else{
            if (modalData.c_store_code) {
                data.c_id = modalData.c_id
                couponResult = await updateCoupon(JSON.stringify(data))
            } else {
                couponResult = await addCoupon(JSON.stringify(data))
            }
    
            if (couponResult && couponResult.success) {
                message.success("Successfully added")
                let coupons = await getStoreCoupons(JSON.stringify({ c_store_code: storeCode }))
                setTableData(coupons.data)
            }
    
            setModal2Visible(false)
        } 
    }

    return (
        <Modal
            title={modalData.c_store_code ? `${modalData.c_store_code}  Data` : "Add New Coupon"}
            centered
            visible={modal2Visible}
            destroyOnClose={true}
            footer={null}
            onOk={() => setModal2Visible(false)}
            onCancel={() => setModal2Visible(false)}
            width={1000}>

            <Form name="basic" labelCol={{ span: 4, }} wrapperCol={{ span: 20, }} onFinish={handleSubmit} autoComplete="off"
                fields={[
                    {
                        name: ["Store Code"],
                        value: storeCode,
                    },
                    {
                        name: ["Coupon Code"],
                        value: couponCode
                    },
                    {
                        name: ["details"],
                        value: details
                    },
                    {
                        name: ["Short Title"],
                        value: shortTitle
                    },
                    {
                        name: ["main text"],
                        value: mainText
                    },
                    {
                        name: ["verified"],
                        value: verified
                    },
                    {
                        name: ["newUser"],
                        value: newUser
                    },
                    {
                        name: ["exclusiveCoupon"],
                        value: c_exclusive_coupon
                    },
                    {
                        name: ["displayOrder"],
                        value: displayOrder
                    },

                ]}
            >

                <Form.Item
                    label="Store Code"
                    name="Store Code"
                    style={{ marginTop: "10px" }}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Store Code!',
                        },
                    ]}
                >
                    {/* <Input name="sc" value={storeCode} onChange={(e) => { setStoreCode(e.target.value) }} /> */}
                    <AutoComplete
                        dropdownClassName="certain-category-search-dropdown"
                        dropdownMatchSelectWidth={200}
                        options={search}
                        style={{ width: "100%" }}
                        value={storeCode}
                        onSearch={onSearch}
                        onSelect={onSelect}

                    ></AutoComplete>
                </Form.Item>


                <Form.Item
                    label="Coupon Code"
                    name="Coupon Code"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Coupon Code!',
                        },
                    ]}>
                    <Input value={couponCode} onChange={(e) => { setCouponCode((e.target.value).toUpperCase()) }} />
                </Form.Item>

                <Form.Item name="details" label="details" >
                    <Input value={details} onChange={(e) => { setDetails(e.target.value) }} />
                </Form.Item>

                <Form.Item label="expired date" name="expired" >
                    <DatePicker selected={expireDate} onChange={(date) => setExpireDate(date)} dateFormat="yyyy/MM/dd" />
                </Form.Item>

                <Form.Item
                    label="Short Title"
                    name="Short Title"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter short title!',
                        },
                    ]}
                >
                    <Input value={shortTitle} onChange={(e) => { setShortTitle(e.target.value) }} />
                </Form.Item>
                <Form.Item
                    label="main text"
                    name="main text"
                >
                    <Input value={mainText} onChange={(e) => { setMainText(e.target.value) }} />
                </Form.Item>

                <Form.Item label="displayOrder" name="displayOrder" rules={[
                    {
                        required: true,
                        message: 'Please enter displayOrder!',
                    },
                ]} >
                    <Input type="number" value={displayOrder} onChange={(e) => { setDisplayOrder(e.target.value) }} />
                </Form.Item>

                <Form.Item label="Categories" name="categories">
                    <Select mode="multiple" style={{ width: '100%' }} defaultValue={defaultCategory} placeholder="Add Categories" onChange={handleCategoryChange}>
                        {categoryList}
                    </Select>
                </Form.Item>
                <Form.Item label="Banks" name="banks">
                    <Select mode="multiple" style={{ width: '100%' }} defaultValue={defaultBank} placeholder="Add Banks" onChange={handleBankChange}>
                        {bankList}
                    </Select>
                </Form.Item>
                <Form.Item label="Coupon Verified" name="verified" >
                    <Checkbox
                        checked={verified}
                        onChange={(e) => { setVerified(e.target.checked) }}
                    >
                    </Checkbox>
                </Form.Item>

                <Form.Item label="New User" name="newUser" >
                    <Checkbox
                        checked={newUser}
                        onChange={(e) => { setNewUser(e.target.checked) }}
                    >
                    </Checkbox>
                </Form.Item>

                <Form.Item label="Exclusive Coupon" name="exclusiveCoupon" >
                    <Checkbox
                        checked={c_exclusive_coupon}
                        onChange={(e) => { setExclusiveCoupon(e.target.checked) }}
                    >
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 12,
                        span: 16,
                    }}>
                    <Button type="primary" htmlType="submit" /*onClick={(e)=>{e.preventDefault()}}*/>
                        Submit
                    </Button>
                </Form.Item>


            </Form>

        </Modal>
    )


}

export default CouponModel;

