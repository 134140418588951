import AuthEndpoints from '../../../config/endpoints/image';
import useRequest from '../index';


const { uploadImage } = AuthEndpoints;


const useImage = () => {
    

    const {post} = useRequest()
    

    const uploadImageFunc = async (bodyFormData) => post(uploadImage,bodyFormData)


    return { uploadImageFunc }

}

export default useImage;