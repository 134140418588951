import React, { useState, useEffect } from 'react';
import { Table, Tag, Space,Upload, Popconfirm, Button, Input, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { Container, TopHeader } from './index.styled'
import SearchIcon from '../../../media/search.png'
import UIModal from '../ReUsable/Model/bankModal';
import { ExportToCsv } from 'export-to-csv';
import useBanks from '../../../hooks/api/banks';
import { useSelector } from 'react-redux';

const { Search } = Input;
const Banks = () => {


  const { getBanks, deleteBank,bulkBankupload } = useBanks()


  const deleteText = 'Are you sure to delete this?';
  const [tableData, setTableData] = useState([])
  const [modal2Visible, setModal2Visible] = useState(false)
  const [originalData, setOriginalData] = useState([])
  const [modalData, setModalData] = useState({})


  let newData

  const confirmDelete = async (record) => {
    let data = {
      b_code: record.b_code
    }

    await deleteBank(data)

    setModal2Visible(false)
    newData = await getBanks();
    setTableData(newData.data)
  }


  const deleteFuction = async (record) => {



  }

  const onEdit = (record) => {
    setModalData(record);
    setModal2Visible(true);
  }

  const columns = [
    {
      title: 'Bank Name',
      dataIndex: 'b_display_name',
      key: 'b_display_nameme',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Bank Code',
      dataIndex: 'b_code',
      key: 'b_code',
    },
    {
      title: 'Bank Score',
      dataIndex: 'b_score',
      key: 'b_score',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button id={record.b_id} onClick={() => { onEdit(record) }}>Edit</Button>
          {/* <Popconfirm
            placement="topRight"
            title={deleteText}
            onConfirm={()=>{confirmDelete(record)}}
            okText="Yes"
            cancelText="No"
          >
          <Button id={record.b_id} >Delete</Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];


  useEffect(async () => {
    let newData = await getBanks();
    setTableData(newData.data)
    setOriginalData(newData.data)

  }, []);

  const SearchBanks = () => {
    let input = document.querySelector(".ant-input").value.toLowerCase();
    if (input.length > 0) {
      let filteredBanksData = originalData.filter((row, index) => {
        if ((row.b_display_name).toLocaleLowerCase().includes(input)) {
          return row
        }
      })
      setTableData(filteredBanksData);
    } else {
      setTableData(newData.data);
    }
  }

  const uploadprops = {
    name: 'file',
    accept: ".csv",

  };

  const uploadfile = async (info) => {
    if (info.file.status != "uploading") {
      var bodyFormData = new FormData();
      bodyFormData.append('list', info.file.originFileObj);
      let result = await bulkBankupload(bodyFormData)
      if (result.success) {
        if (result.errorList.length > 0) {
          download(result.errorList)
        } else {
          message.success("Uploaded Successfully");
        }
      }
    }

  }

  const download = (data) => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'CouponError' + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
      filename: 'CouponError' + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

  }

  // const fromBank = useSelector(state => state.banks )
  // console.log("fromBank",fromBank)
  // setTableData(fromBank)

  // displaying table 
  return <Container>
    <TopHeader><Search placeholder="Search Here" className="search-input" onChange={() => { SearchBanks() }} enterButton />
        <Upload {...uploadprops} onChange={uploadfile}>
          <Button icon={<UploadOutlined />}>Bulk Upload</Button>
        </Upload>
    <Button onClick={() => { setModalData({}); setModal2Visible(true) }}>Add Bank</Button></TopHeader>
    <Table columns={columns} dataSource={tableData} />
    {modal2Visible && <UIModal modal2Visible={modal2Visible} modalData={modalData} setModal2Visible={setModal2Visible} setTableData={setTableData} setOriginalData={setOriginalData} />}
  </Container>

}


export default Banks;
