import React, {useState, useEffect} from 'react';
import {
    Table,
    Tag,
    Space,
    Upload,
    Popconfirm,
    Button,
    Input,
    message
} from 'antd';
import {UploadOutlined} from '@ant-design/icons';

import {Container, TopHeader} from './index.styled'
import SearchIcon from '../../../media/search.png'
import UIModal from '../ReUsable/Model/storeModal';
import {ExportToCsv} from 'export-to-csv';
import useStores from '../../../hooks/api/stores/useStores';
import {useSelector} from 'react-redux';

const {Search} = Input;
const Stores = () => {

    const {
        getStores,
        deleteStore,
        bulkStoreupload,
        getDealsDump,
        getCouponsDump
    } = useStores()


    const deleteText = 'Are you sure to delete this?';
    const [tableData, setTableData] = useState([])
    const [modal2Visible, setModal2Visible] = useState(false)
    const [originalData, setOriginalData] = useState([])
    const [modalData, setModalData] = useState({})


    let newData

    const confirmDelete = async (record) => {
        let data = {
            s_code: record.s_code
        }

        await deleteStore(data)

        setModal2Visible(false)
        newData = await getStores();
        setTableData(newData.data)
    }


    const deleteFuction = async (record) => {}

    const onEdit = (record) => {
        setModalData(record);
        setModal2Visible(true);
    }

    const onDownload = async (record) => {

        let dealsData = await getDealsDump({d_store_code: record.s_code});
        let couponsData = await getCouponsDump({c_store_code: record.s_code});
        
        let finalDeals = dealsData.data.map((deal) => {
          let newObj = {
              store_code: deal.d_store_code,
              expire_date: deal.d_expire_date.slice(0, deal.d_expire_date.indexOf("T0")),
              details: deal.d_details,
              short_title: deal.d_short_title,
              is_coupon_verified: deal.d_is_coupon_verified,
              main_text: deal.d_main_text,
              new_user: deal.d_new_user,
              display_order: deal.d_display_order,
              entry_date: deal.d_entry_date.slice(0, deal.d_entry_date.indexOf("T0")).replaceAll("-", "/").split("/").reverse().join("/"),
              type: deal.d_type,
              affliate_url: deal.d_affliate_url,
              category1: deal.category1,
              category2: deal.category2,
              category3: deal.category3,
              category4: deal.category4,
              bank1: deal.bank1,
              bank2: deal.bank2,
              bank3: deal.bank3,
              bank4: deal.bank4
          }
          return newObj
        })

        let finalCoupons = couponsData.data.map((coupon) => {
        let newObj = {
            store_code: coupon.c_store_code,
            expire_date: coupon.c_expire_date.slice(0, coupon.c_expire_date.indexOf("T0")),
            details: coupon.c_details,
            short_title: coupon.c_short_title,
            is_coupon_verified: coupon.c_is_coupon_verified,
            main_text: coupon.c_main_text,
            new_user: coupon.c_new_user,
            display_order: coupon.c_display_order,
            entry_date: coupon.c_entry_date.slice(0, coupon.c_entry_date.indexOf("T0")).replaceAll("-", "/").split("/").reverse().join("/"),
            type: coupon.c_type,
            coupon_code: coupon.coupon_code,
            category1: coupon.category1,
            category2: coupon.category2,
            category3: coupon.category3,
            category4: coupon.category4,
            bank1: coupon.bank1,
            bank2: coupon.bank2,
            bank3: coupon.bank3,
            bank4: coupon.bank4
        }

        return newObj
        })

        if(finalDeals.length > 0){
            download(finalCoupons, record.s_code+"-coupon-")
        }

        if(finalDeals.length > 0){
            download(finalDeals, record.s_code+"-deal-")
        }

    }

    const columns = [
        {
            title: 'Store Name',
            dataIndex: 's_display_name',
            key: 's_display_name',
            render: text => <a>{text}</a>
        },
        {
            title: 'Store Code',
            dataIndex: 's_code',
            key: 's_code'
        },
        {
            title: 'Store Score',
            dataIndex: 's_score',
            key: 's_score'
        },
        {
            title: 'Download coupon & deal',
            key: 'action',
            dataIndex: 's_redirect_url',
            render: (text, record) => <Space size="middle">
                <Button id={
                        record.s_code
                    }
                    onClick={
                        () => {
                            onDownload(record)
                        }
                }>Download</Button>
            </Space>
        }, {
            title: 'Download Coupons & Deals',
            key: 's_redirect_url',
            dataIndex: 's_redirect_url',
            render: text => <Tag color={
                text ? "green" : "volcano"
            }>
                {
                text ? "Affiliate" : "Official"
            } </Tag>
        }, {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button id={
                            record.s_id
                        }
                        onClick={
                            () => {
                              
                                onEdit(record)
                            }
                    }>Edit</Button>
                    {/* <Popconfirm
            placement="topRight"
            title={deleteText}
            onConfirm={()=>{confirmDelete(record)}}
            okText="Yes"
            cancelText="No"
          >
          <Button id={record.s_id} >Delete</Button>
          </Popconfirm> */} </Space>
            )
        },
    ];


    useEffect(async () => {
        let newData = await getStores();
        setTableData(newData.data)
        setOriginalData(newData.data)

    }, []);

    const SearchStores = () => {
        let input = document.querySelector(".ant-input").value.toLowerCase();
        if (input.length > 0) {
            let filteredStoresData = originalData.filter((row, index) => {
                if ((row.s_display_name).toLocaleLowerCase().includes(input)) {
                    return row
                }
            })
            setTableData(filteredStoresData);
        } else {
            setTableData(newData.data);
        }
    }

    const uploadprops = {
        name: 'file',
        accept: ".csv"

    };

    const uploadfile = async (info) => {
        if (info.file.status != "uploading") {
            var bodyFormData = new FormData();
            bodyFormData.append('list', info.file.originFileObj);
            let result = await bulkStoreupload(bodyFormData)
            if (result.success) {
                if (result.errorList.length > 0) {
                    download(result.errorList)
                } else {
                    message.success("Uploaded Successfully");
                }
            }
        }

    }

    const download = (data, name) => {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: name + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
            filename: name + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

    }

    // const fromStore = useSelector(state => state.stores )
    // console.log("fromStore",fromStore)
    // setTableData(fromStore)

    // displaying table
    return <Container>
        <TopHeader><Search placeholder="Search Here" className="search-input"
                onChange={
                    () => {
                        SearchStores()
                    }
                }
                enterButton/>
            <Upload {...uploadprops}
                onChange={uploadfile}>
                <Button icon={<UploadOutlined/>}>Bulk Upload</Button>
            </Upload>
            <Button onClick={
                () => {
                    setModalData({});
                    setModal2Visible(true)
                }
            }>Add Store</Button>
        </TopHeader>
        <Table columns={columns}
            dataSource={tableData}/> {
        modal2Visible && <UIModal modal2Visible={modal2Visible}
            modalData={modalData}
            setModal2Visible={setModal2Visible}
            setTableData={setTableData}
            setOriginalData={setOriginalData}/>
    } </Container>

}


export default Stores;
