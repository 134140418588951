import  { SET_AUTH, SET_USER, SET_TOKEN, SET_AUTH_STATE } from "../actions/types";


const initState = {
    user : {},
    auth : false,
    token : localStorage.getItem('token'),
    status: "loaded"
}

export default function(state = initState, action){

    switch(action.type){
        case SET_AUTH:
            return {
                ...state,
                auth: action.payload
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload
            }
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload || localStorage.getItem('token')
            }
        case SET_AUTH_STATE:
            return {
                ...state,
                status: action.payload
            }
        default : return state
    }
    
}