

import AuthEndpoints from '../../../config/endpoints/deals';
import useRequest from '../index';


const { getstoredeal, adddeal, updatedeal, getallstores, getallcategories, getallbanks, getdealscategory, dealdelete, bulkdealupload } = AuthEndpoints;

const useDeal = (data) => {


    /* LOCAL STATES */


    /* HOOKS */
    const { get, post } = useRequest()



    const getallStores = async () => get(getallstores)
    const getAllCategories = async () => get(getallcategories)
    const addDeal = async (data) => post(adddeal, data)
    const getstoredeals = async (data) => post(getstoredeal, data)
    const updateDeal = async (data) => post(updatedeal, data)
    const getDealCategory = async (data) => post(getdealscategory, data)
    const dealDelete = async (data) => post(dealdelete, data)
    const bulkDealUpload = async (data) => post(bulkdealupload, data)
    const getAllBanks = async () => post(getallbanks)



    return { getstoredeals, getallStores, addDeal, updateDeal, getAllCategories, getDealCategory, dealDelete, bulkDealUpload, getAllBanks }

}

export default useDeal;