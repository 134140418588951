

import AuthEndpoints from '../../../config/endpoints/coupons';
import useRequest from '../index';


const { getstorecoupon, addcoupon, updatecoupon, getallstores, getallcategories, getcouponcategory,getcouponbank, deleteurl, bulkcouponupload, getallbanks } = AuthEndpoints;

const useCoupons = (data) => {


    /* LOCAL STATES */


    /* HOOKS */
    const { get, post } = useRequest()



    const getallStores = async () => get(getallstores)
    const getallCategories = async () => get(getallcategories)
    const addCoupon = async (data) => post(addcoupon, data)
    const getStoreCoupons = async (data) => post(getstorecoupon, data)
    const updateCoupon = async (data) => post(updatecoupon, data)
    const getCouponCategory = async (data) => post(getcouponcategory, data)
    const getCouponBank = async (data) => post(getcouponbank,data)
    const deleteUrl = async (data) => post(deleteurl, data)
    const bulkCouponUpload = async (data) =>{console.log(data); post(bulkcouponupload, data)}
    const getallBanks = async () => get(getallbanks)



    return { getStoreCoupons, getallStores, addCoupon, updateCoupon, getallCategories, getCouponCategory,getCouponBank, deleteUrl, bulkCouponUpload, getallBanks }

}

export default useCoupons