import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    justify-content: center;
    width:100%;

`

export const Wrapper = styled.div`
     border: 1px solid #f0f0f0;
     width:80%;
    
`

export const ImageContainer = styled.div`
    width:100%;
    
    margin:20;
    height:200px;
    display:flex;
    justify-content: space-evenly;
    align-items : center;
    .ant-upload-picture-card-wrapper{
        width:fit-content;
    }
`;

export const ResultContainer = styled(ImageContainer)`
    justify-content: center;
    align-items:unset;
`