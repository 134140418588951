// import React, { Component, useEffect } from 'react'
// import { useSelector, connect } from 'react-redux'
// import { Redirect, Route, useHistory } from "react-router-dom"
// // import GlobalLoader from '../Desktop/Pages/SecondaryPages/GlobalLoader'
// import Plan from '../Desktop/Pages/PaymentPages/Plan'
// import DashboardLayout from '../Desktop/Layout'

// export const PrivateRoute = ({ 
//     isAuth,
//     component: Component, 
//     ...rest 
// }) => {

//     useEffect(() => {
//     }, [authState])

//     return <Route 
//             {...rest}
//             component={(props) => {
//                 if(authState === 'loading' || authState === 'init') return "Loading"
//                 // <GlobalLoader /> 

//                 if(authState === 'loaded'){
//                     return isAuth ? 
//                     <div>
//                             <DashboardLayout>
//                                 <Component {...rest} {...props}/>
//                             </DashboardLayout>
//                     </div> :  <Redirect to={{
//                                     pathname: '/dashboard/login',
//                                     state: {
//                                         from: props.location
//                                     }
//                                 }}
//                             />
//                 }
//             }}
//         />
// }

// const mapStateToProps = (state) => ({
//     isAuth: state.personal.auth,
// })

// export default connect(mapStateToProps)(PrivateRoute)

import React, { Component, useEffect,useState } from 'react'
import { Route,Navigate,useNavigate } from 'react-router-dom'



const PrivateRoute = ({ children }) =>{
   
    let refreshToken = localStorage.getItem('token') ? true : false

    

    
    
    return refreshToken ? children : <Navigate to="/" />
}

export default PrivateRoute;

