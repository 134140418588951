import { Form, Input, Button,message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

import useLogin from "../../../hooks/api/auth/useLogin"
import {LoginWarapper,Container} from "./index.styled"


const NormalLoginForm = () => {

  const history = useNavigate()

  const {onSubmit} = useLogin()



  const onFinish = async(values) => {
    let result = await onSubmit(JSON.stringify(values) )
    if(result && result.success)
    {
        localStorage.setItem('token', result.xAuth)
        history('/insight', { replace: false })
    }
  };


  
  return (
      <LoginWarapper>
          <Container>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            >
            <Form.Item
                name="username"
                rules={[
                {
                    required: true,
                    message: 'Please input your Username!',
                },
                ]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <br/>
            <Form.Item
                name="password"
                rules={[
                {
                    required: true,
                    message: 'Please input your Password!',
                },
                ]}
            >
                <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
                </Button>
               
            </Form.Item>
            </Form>
        </Container>
      </LoginWarapper>
    
  );
};

export default NormalLoginForm


// ReactDOM.render(<NormalLoginForm />, mountNode);