import {BASE_URL} from "../constants"
const rootRoute = '/api/panel/store'

export default {
    getStoresList: `${BASE_URL}${rootRoute}/getallstore`,
    updateStoreURL: `${BASE_URL}${rootRoute}/updatestore`,
    addNewStore: `${BASE_URL}${rootRoute}/addstore`,
    deleteStoreUrl:`${BASE_URL}${rootRoute}/deletestore`,
    bulkstoreupload: `${BASE_URL}${rootRoute}/bulkupdate`,
    getstorecouponanddeals: `${BASE_URL}${rootRoute}/storecouponanddeals`
}
