

import AuthEndpoints from '../../../config/endpoints/stores';
import CouponsEndpoint from '../../../config/endpoints/coupons';
import DealsEndpoint from '../../../config/endpoints/deals';

import useRequest from '../index';


const { getStoresList,updateStoreURL,addNewStore,deleteStoreUrl,bulkstoreupload, getstorecouponanddeals } = AuthEndpoints;
const { getcouponsdump } = CouponsEndpoint;
const { getdealsdump } = DealsEndpoint;

const useStores = (data) => {


    /* LOCAL STATES */
    

    /* HOOKS */
    const {get,post} = useRequest()

   

    const getStores = async () => get(getStoresList)
    const updateStore = async (data) => post(updateStoreURL,data)
    const addStore = async (data) => post(addNewStore,data)
    const deleteStore = async (data) => post(deleteStoreUrl,data)
    const bulkStoreupload = async (data) => post(bulkstoreupload,data)
    const getStoreCouponAndDeals = async (data) => post(getstorecouponanddeals,data)
    const getCouponsDump = async (data) =>  post(getcouponsdump, data) 
    const getDealsDump = async (data) => post(getdealsdump, data)

    return { getStores,updateStore,addStore,deleteStore,bulkStoreupload, getStoreCouponAndDeals, getCouponsDump, getDealsDump }

}

export default useStores