

import AuthEndpoints from '../../../config/endpoints/banners';
import useRequest from '../index';


const { getBannersList,updateBannerURL,addNewBanner,deleteBannerUrl } = AuthEndpoints;

const useBanners = (data) => {


    /* LOCAL STATES */
    

    /* HOOKS */
    const {get,post} = useRequest()

   

    const getBanners = async () => get(getBannersList)
    const updateBanner = async (data) => post(updateBannerURL,data)
    const addBanner = async (data) => post(addNewBanner,data)
    const deleteBanner = async (data) => post(deleteBannerUrl,data)


   

    return { getBanners,updateBanner,addBanner,deleteBanner }

}

export default useBanners