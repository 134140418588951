

import AuthEndpoints from '../../../config/endpoints/home';
import useRequest from '../index';


const { getInsightCountUrl,getRecentDataUrl,getDownloadUrl } = AuthEndpoints;

const useInsight = (data) => {


    /* LOCAL STATES */
    

    /* HOOKS */
    const {get,post} = useRequest()

   

    const getInsightCount = async () => get(getInsightCountUrl)
    const getRecentData = async () => get(getRecentDataUrl)
    const getDownloadData = async () => get(getDownloadUrl)
    


   

    return { getInsightCount,getRecentData,getDownloadData}

}

export default useInsight