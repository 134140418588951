import styled from "styled-components";

export const Container = styled.div`

`;

export const TopHeader = styled.div`
display: flex;
justify-content : space-between



`;
