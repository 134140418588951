import { message } from 'antd';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';







const useRequest = () => {

    let refreshToken = localStorage.getItem('token') 


    /* HOOKS */
    const history = useNavigate()


    const makeRequest = async(method,url,data) =>{
        try{
            console.log("new:",url)
        
            /* REQUEST */
            let response = await axios({
                method: method,
                url: url,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json', 
                    'x-auth' : refreshToken
                },
                data: data,
            })
    
            console.log(response.data)
    
            /* SUCCESS */
            if(response.data.success){

            }else{
                message.error(response.data && response.data.msg ? response.data.msg : "Error")
            } 
            return response.data
        }catch(e){
                /* ERROR */
            if (e.response && e.response.status === 403) {
                localStorage.removeItem('token') 
                history('/', { replace: false })
            }
    
                
        }
    }
    
     const get = async (url) => makeRequest('GET', url, undefined)

     const put = async(url, data) => makeRequest('PUT', url, data)

     const post = async(url, data) => makeRequest('POST', url, data)

     const del = async(url, data) => makeRequest('DELETE', url, data)

    return {get,put,post,del}
}

export default useRequest


