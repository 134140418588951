import styled from "styled-components";


export const WrapperContainer = styled.div`

`;

export const CountsContainer = styled.div`
    display: grid;
    // grid-template-columns: repeat(4,1fr);
    grid-template-columns: 2fr 2fr 3fr 3fr;
    height: 150px;
`;

export const CountsCard = styled.div`
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    box-shadow: 0px 0px 2px 0px grey;
    border-radius: 4px;
    h2{
        margin: 0 auto;
        text-align: center;
        color: grey;
    }
    p{
        font-size: 24px;
    margin: 0 auto;
    }
`;

export const SubCountsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
`;

export const SubCountsLeft = styled.div`
    width: 50%;
    text-align: center;
    box-shadow: 1px -1px 2px 0px #80808030;
    border-radius: 4px;
    h3{
        margin: 0 auto;
        text-align: center;
        color: #808080e6;
    }
    p{
        font-size: 24px;
        margin: 0 auto;
    }
`;

export const SubCountsRight = styled.div`
    width: 50%;
    text-align: center;
    box-shadow: 0px -1px 2px 0px #80808030;
        border-radius: 4px 0px;
    h3{
        margin: 0 auto;
        text-align: center;
        color: #808080e6;
    }
    p{
        font-size: 24px;
        margin: 0 auto;
    }
`;

export const Ruler = styled.div`
    width: 100%;
    height: 1px;
    background: #80808012;
    margin: 10px 0px;
`;
export const EmptyStores = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

export const NoCouponCard = styled.div`
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 0px 2px 0px grey;
    border-radius: 4px;
    h2{
        margin: 0 auto;
        text-align: center;
        color: grey;
    }
    p{
        font-size: 24px;
        margin: 0 auto;
    }
`;

export const LevelTwo = styled.div`
    display: grid;
    grid-template-columns: 7fr 3fr;
`;

export const ExclusiveCountsContainer = styled.div`
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 0px 2px 0px grey;
    border-radius: 4px;
    h2{
        color: grey;
    }
`;

export const ExclusiveCounts = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
`;

export const ExclusiveCountsLeft = styled.div`
    width: 50%;
    text-align: center;
    box-shadow: 1px -1px 2px 0px #80808030;
    border-radius: 4px;
    h3{
        margin: 0 auto;
        text-align: center;
        color: #808080e6;
    }
    p{
        font-size: 24px;
    margin: 0 auto;
    }
`;

export const ExclusiveCountsRight = styled.div`
    width: 50%;
    text-align: center;
    box-shadow: 0px -1px 2px 0px #80808030;
    border-radius: 4px 0px;
    h3{
        margin: 0 auto;
        text-align: center;
        color: #808080e6;
    }
    p{
        font-size: 24px;
    margin: 0 auto;
    }
`;

export const RecentlyAddedHeader = styled.div`
    display:flex;
    justify-content : space-between;
    padding-right :20px;
`

export const RecentlyAdded = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

export const RecentStores = styled.div`
    margin: 20px;
    padding: 5px;
    box-shadow: 0px 0px 2px 0px #80808091;
    border-radius: 3px;
    h3{
        color: grey;
        text-align: center;
    }
`;

export const RecentCoupons = styled.div`
    margin: 20px;
    padding: 5px;
    box-shadow: 0px 0px 2px 0px #80808091;
    border-radius: 3px;
    h3{
        color: grey;
        text-align: center;
    }
`;

export const RecentDeals = styled.div`
    margin: 20px;
    padding: 5px;
    box-shadow: 0px 0px 2px 0px #80808091;
    border-radius: 3px;
    h3{
        color: grey;
        text-align: center;
    }
`;