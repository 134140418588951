import React, { useState, useEffect } from 'react';
import { Table, Tag, Space, Popconfirm, Button, Input, AutoComplete, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';


import useDeal from '../../../hooks/api/deals/useDeals';
import { Container, TopHeader } from './index.styled'
import DealModel from "../ReUsable/Model/dealsModal"

let searchData = []


const Deals = () => {

    const deleteText = 'Are you sure to delete this?';
    const [tableData, setTableData] = useState([])
    const [modal2Visible, setModal2Visible] = useState(false)
    const [modalData, setModalData] = useState({})

    const [options, setOptions] = useState([])

    const { getstoredeals, getallStores, addDeal, dealDelete, getDealCategory, bulkDealUpload } = useDeal()

    const confirmDelete = async (record) => {

        console.log("Doneee")
        let deletedRecord = await dealDelete(JSON.stringify({ d_id: record.d_id }))
    
        if (deletedRecord.success) {
          message.success("Successfully Deleted");
          let deals = await getstoredeals(JSON.stringify({ d_store_code: record.d_store_code }))
          setTableData(deals.data)
        }
    
      }


    useEffect(async () => {
        searchData = await getallStores();
        setOptions(searchData.data)

    }, []);


    const onSearch = (e) => {
        let input = e.toLowerCase();
        if (input.length > 0) {
            let filteredStoresData = searchData.data.filter((row, index) => {
                if ((row.value).toLowerCase().includes(input)) {
                    return row
                }
            })
            setOptions(filteredStoresData);
        } else {
            setOptions(searchData.data);
        }
    }

    const onSelect = async (e) => {
        console.log(e)
        let deals = await getstoredeals(JSON.stringify({ d_store_code: e }))
        console.log(deals.data)
        setTableData(deals.data)
    }


    const onEdit = async (record) => {
        let selectedCategories = await getDealCategory(JSON.stringify({ deal_id: modalData.d_id }))
        let defaultSelect = []
        selectedCategories.data.forEach((item, i) => {
            defaultSelect.push(item.d_category_code)
        })
        record.defaultValue = defaultSelect
        setModalData(record);
        setModal2Visible(true);
    }


    const columns = [
        {
            title: 'Store Code',
            dataIndex: 'd_store_code',
            key: 'd_store_code',
            render: text => <a href={`https://couponswala.com/store/${text}-offers`}>{text.charAt(0).toUpperCase() + text.slice(1)}</a>,
        },
        {
            title: 'Redirect Url',
            key: 'd_redirect_url',
            dataIndex: 'd_redirect_url',
            render: text => <Tag color={text ? "green" : "volcano"} >
                {text ? "Affiliate" : "Official"}
            </Tag>
        },

        {
            title: 'Short Title',
            dataIndex: 'd_short_title',
            key: 'd_short_title',
        },
        {
            title: 'Expire Date',
            key: 'd_expire_date',
            dataIndex: 'd_expire_date',
            render: text => {
                var pickedDate = moment(text).format('YYYY-MM-DD')
                pickedDate = pickedDate.split("-")
                let dateToCompare = new Date(parseInt(pickedDate[0]), parseInt(pickedDate[1] - 1), parseInt(pickedDate[2]));

                return <Tag color={new Date(dateToCompare.toDateString()) >= new Date(new Date().toDateString()) ? "green" : "volcano"} >
                    {new Date(dateToCompare.toDateString()) >= new Date(new Date().toDateString()) ? "Live" : "Expired"}
                </Tag>
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button id={record.s_id} onClick={() => { onEdit(record) }}>Edit</Button>
                    <Popconfirm
                        placement="topRight"
                        title={deleteText}
                        onConfirm={() => { confirmDelete(record) }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button id={record.s_id} >Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const download = (data) => {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'DealErrror' + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
            filename: 'DealErrror' + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

    }

    const uploadprops = {
        name: 'file',
        accept: ".csv",

    };

    const uploadfile = async (info) => {
        if (info.file.status != "uploading") {
            var bodyFormData = new FormData();
            bodyFormData.append('list', info.file.originFileObj);
            let result = await bulkDealUpload(bodyFormData)
            if (result.success) {
                if (result.errorList.length > 0) {
                    download(result.errorList)
                } else {
                    message.success("Uploaded Successfully");
                }
            }
        }

    }

    return (
        <Container>
            <TopHeader>

                <AutoComplete
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={200}
                    options={options}
                    style={{ width: "500px" }}
                    onSearch={onSearch}
                    onSelect={onSelect}
                >
                    <Input.Search size="large" placeholder="Enter the store name" />
                </AutoComplete>
                <div>
                    <Button onClick={() => { setModalData({}); setModal2Visible(true) }}>Add Deal</Button>
                    <Upload {...uploadprops} onChange={uploadfile}>
                        <Button icon={<UploadOutlined />}>Bulk Upload</Button>
                    </Upload>
                </div>

            </TopHeader>
            <Table columns={columns} dataSource={tableData} locale={{ emptyText: 'Enter Store name to see coupon' }} />
            {modal2Visible && <DealModel modal2Visible={modal2Visible} modalData={modalData} setModal2Visible={setModal2Visible} setTableData={setTableData} searchData={searchData.data} />}
        </Container>
    )
}

export default Deals