

import AuthEndpoints from '../../../config/endpoints/blogs';
import useRequest from '../index';


const { getBlogsList, updateBlogURL, addNewBanner, deleteBannerUrl } = AuthEndpoints;

const useBlogs = (data) => {


    /* LOCAL STATES */


    /* HOOKS */
    const { get, post } = useRequest()



    const getBlogs = async () => get(getBlogsList)
    const updateBlogs = async (data) => post(updateBlogURL, data)




    return { getBlogs, updateBlogs }

}

export default useBlogs