import  { SET_DEALS } from "../actions/types";


const initState = {
    deals :[],
}

export default function(state = initState, action){
    switch(action.type){
        case SET_DEALS:
            return {
                ...state,
                deals: action.payload
            }
    }
    return state;
}

