import  { SET_STORES } from "../actions/types";


const initState = []

export default function(state = initState, action){
    switch(action.type){
        case SET_STORES:{
            return[...state,"suji"] 
        }
            
        default: return state
    }
   
}

