import React, { useEffect, useState } from 'react';
import { HeaderContent, BodyContainer, Title, Description, RedirectUrl, ImageUrl, SubmitBtn, TitleValidation, DescriptionValidation, RedirectUrlValidation, ImageUrlValidation } from './index.styled'
import { message } from 'antd';

const Notification = () => {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [redirectUrl, setRedirectUrl] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [tileValidation, setTitleValidation] = useState(true)
    const [descriptionValidation, setDescriptionValidation] = useState(true)
    const [redirectUrlValidation, setRedirectUrlValidation] = useState(true)
    const [imageValidation, setImageValidation] = useState(true)

    useEffect(async () => {
        console.log("inside notification useEffect!!")
    }, [])

    const submitFunc = () => {

        if (!title) {

            setTitleValidation(false)
        }
        else {

            setTitleValidation(true)
        }

        if (!description) {

            setDescriptionValidation(false)
        }
        else {

            setDescriptionValidation(true)
        }
        if (!redirectUrl) {

            setRedirectUrlValidation(false)
        }
        else {

            setRedirectUrlValidation(true)
        }
        if (!imageUrl || !(imageUrl.endsWith(".png"))) {

            setImageValidation(false)
        }
        else if (imageUrl.endsWith(".png")) {

            setImageValidation(true)
        }


        if (title && description && redirectUrl && imageUrl.endsWith(".png")) {

            fetch(`https://testbackend.couponswala.com/api/website/notification/pushNotification`, {
                method: "POST",
                body: JSON.stringify({
                    title: title,
                    body: description,
                    click_action: redirectUrl,
                    icon: imageUrl
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
                .then(response => response.json())
                .then(json => console.log(json));
            message.success("Notification sent successfully");
        }

    }
    const titleInput = (e) => {

        setTitle(e.target.value)

    }

    const DescriptionInput = (e) => {

        setDescription(e.target.value)
    }

    const RedirectURLInput = (e) => {

        setRedirectUrl(e.target.value)
    }

    const ImageURLInput = (e) => {

        //if ((e.target.value).endsWith(".png")) {
        setImageUrl(e.target.value)
        // }
    }
    return (
        <div>
            <div>
                <HeaderContent>
                    <h1>Notification</h1>
                </HeaderContent>
            </div>
            <div>
                <BodyContainer>
                    <div>
                        {tileValidation ?
                            <Title>
                                <h1>Title</h1>
                                <input type="text" id="title" name="title" value={title} onChange={(e) => {
                                    titleInput(e);
                                    setTitleValidation(true)
                                }}></input>
                            </Title>
                            :
                            <TitleValidation>
                                <h1>Title</h1>
                                <input type="text" id="title" name="title" value={title} onChange={(e) => {
                                    titleInput(e);
                                    setTitleValidation(true)
                                }}></input>
                                <p>*title field is required</p>
                            </TitleValidation>
                        }

                    </div>
                    <div>
                        {descriptionValidation ?
                            <Description>
                                <h1>Description</h1>
                                <input type="text" id="description" name="description" value={description} onChange={(e) => {
                                    DescriptionInput(e);
                                    setDescriptionValidation(true)
                                }}></input>
                            </Description>
                            :
                            <DescriptionValidation>
                                <h1>Description</h1>
                                <input type="text" id="description" name="description" value={description} onChange={(e) => {
                                    DescriptionInput(e);
                                    setDescriptionValidation(true)
                                }}></input>
                                <p>*description is required </p>
                            </DescriptionValidation>
                        }

                    </div>
                    <div>
                        {redirectUrlValidation ?
                            <RedirectUrl>
                                <h1>Redirect URL</h1>
                                <input type="text" id="redirect-url" name="redirect-url" value={redirectUrl} onChange={(e) => {
                                    RedirectURLInput(e);
                                    setRedirectUrlValidation(true)
                                }}></input>
                            </RedirectUrl>
                            :
                            <RedirectUrlValidation>
                                <h1>Redirect URL</h1>
                                <input type="text" id="redirect-url" name="redirect-url" value={redirectUrl} onChange={(e) => {
                                    RedirectURLInput(e);
                                    setRedirectUrlValidation(true)
                                }}></input>
                                <p>*redirect url is required </p>
                            </RedirectUrlValidation>
                        }
                    </div>
                    <div>
                        {imageValidation ?
                            <ImageUrl>
                                <h1>Image URL</h1>
                                <input type="text" id="image-url" name="image-url" value={imageUrl} onChange={(e) => {
                                    ImageURLInput(e);
                                    setImageValidation(true)
                                }}></input>
                            </ImageUrl>
                            :
                            <ImageUrlValidation>
                                <h1>Image URL</h1>
                                <input type="text" id="image-url" name="image-url" value={imageUrl} onChange={(e) => {
                                    ImageURLInput(e);
                                    setImageValidation(true)
                                }}></input>
                                <p>*image url is required (.png files only)</p>
                            </ImageUrlValidation>
                        }
                    </div>
                    <div><span onClick={submitFunc}><SubmitBtn>Submit</SubmitBtn></span></div>
                </BodyContainer>
            </div>
        </div>
    )
}
export default Notification;