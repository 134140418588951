import  { SET_COUPONS } from "../actions/types";


const initState = {
    coupons :[],
}

export default function(state = initState, action){
    switch(action.type){
        case SET_COUPONS:
            return {
                ...state,
                coupons: action.payload
            }
    }
    return state;
}

