import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { WrapperContainer, CountsContainer, CountsCard, SubCountsContainer, SubCountsLeft, SubCountsRight, Ruler, EmptyStores, NoCouponCard, LevelTwo, ExclusiveCountsContainer, ExclusiveCountsLeft, ExclusiveCountsRight, ExclusiveCounts, RecentlyAddedHeader, RecentlyAdded, RecentStores, RecentCoupons, RecentDeals } from './index.styled';
import useInsight from '../../../hooks/api/home/useInsight';
import { ExportToCsv } from 'export-to-csv';


const Insight = () => {

  const { getInsightCount, getRecentData, getDownloadData } = useInsight()

  const [insightCount, setInsightCount] = useState()
  const [recentData, setRecentData] = useState([])
  const [downloadData, setDownloadData] = useState([])


  const download = (data) => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Stores' + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
      filename: 'Stores' + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

  }

  const storeColumns = [
    // {
    //   title: 'Id',
    //   dataIndex: 'key',
    //   key: 'key',
    // },
    {
      title: 'Name',
      dataIndex: 's_code',
      key: 's_code',
      render: text => <a href={`https://couponswala.com/store/${text}-offers`}>{text.charAt(0).toUpperCase() + text.slice(1)}</a>,
    },
    {
      title: 'Updated By',
      dataIndex: 's_last_update_by',
      key: 's_last_update_by',
      // render : text => <p>{text && text.split("T")[0]}</p>
    }]
  const couponsColumns = [

    {
      title: 'Name',
      dataIndex: 's_code',
      key: 's_code',
      render: text => <a href={`https://couponswala.com/store/${text}-offers`}>{text.charAt(0).toUpperCase() + text.slice(1)}</a>,
    },
    {
      title: 'Updated By',
      dataIndex: 'c_last_update_by',
      key: 'c_last_update_by',
    }]
  const dealsColumns = [

    {
      title: 'Name',
      dataIndex: 's_code',
      key: 's_code',
      render: text => <a href={`https://couponswala.com/store/${text}-offers`}>{text.charAt(0).toUpperCase() + text.slice(1)}</a>,
    },
    {
      title: 'Updated By',
      dataIndex: 'd_last_update_by',
      key: 'd_last_update_by',
    }]

  useEffect(async () => {
    let count = await getInsightCount()
    setInsightCount(count.data)
    let recent = await getRecentData()
    setRecentData(recent.data)
    let download = await getDownloadData()
    setDownloadData(download.data)
  }, [])


  return <WrapperContainer>
    <CountsContainer>
      <CountsCard>
        <h2>
          Total Stores
        </h2>
        <p>
          {insightCount && insightCount.store}
        </p>
      </CountsCard>
      <CountsCard>
        <h2>
          Total Categories
        </h2>
        <p>
          {insightCount && insightCount.category}

        </p>
      </CountsCard>
      <CountsCard style={{ justifyContent: "space-around" }}>
        <h2>
          Total Coupons
        </h2>
        <SubCountsContainer>
          <SubCountsLeft>
            <h3>Active</h3>
            <p>{insightCount && insightCount.liveCoupon}</p>
          </SubCountsLeft>
          <SubCountsRight>
            <h3>Expired</h3>
            <p>{insightCount && insightCount.expiredCoupon}</p>
          </SubCountsRight>
        </SubCountsContainer>
      </CountsCard>
      <CountsCard style={{ justifyContent: "space-around" }}>
        <h2>
          Total Deals
        </h2>
        <SubCountsContainer>
          <SubCountsLeft>
            <h3>Active</h3>
            <p>{insightCount && insightCount.liveDeals}</p>
          </SubCountsLeft>
          <SubCountsRight>
            <h3>Expired</h3>
            <p>{insightCount && insightCount.expiredDeals}</p>
          </SubCountsRight>
        </SubCountsContainer>
      </CountsCard>
    </CountsContainer>
    {/* <Ruler/> */}
    <LevelTwo>
      <EmptyStores>
        <NoCouponCard>
          <h2>
            No Coupon Stores
          </h2>
          <p>{insightCount && insightCount.noCouponsStore}</p>
        </NoCouponCard>
        <NoCouponCard>
          <h2>
            No Deals Stores
          </h2>
          <p>{insightCount && insightCount.noDealsStore}</p>
        </NoCouponCard>
        <NoCouponCard>
          <h2>
            No Coupon & Deals Stores
          </h2>
          <p>{insightCount && insightCount.noCouponDealStore}</p>
        </NoCouponCard>
      </EmptyStores>
      <ExclusiveCountsContainer>
        <h2>Exclusive Coupons</h2>
        <ExclusiveCounts>
          <ExclusiveCountsLeft>
            <h3>
              Active
            </h3>
            <p>{insightCount && insightCount.exclusiveLiveCoupons}</p>

          </ExclusiveCountsLeft>
          <ExclusiveCountsRight>
            <h3>
              Expired
            </h3>
            <p>{insightCount && insightCount.exclusiveExpiredCoupons}</p>

          </ExclusiveCountsRight>
        </ExclusiveCounts>
      </ExclusiveCountsContainer>
    </LevelTwo>
    {/* <Ruler/> */}
    <RecentlyAddedHeader>
      <h2 style={{ margin: "0px 20px" }}>Recently Added</h2>
      <Button type="primary" onClick={() => download(downloadData)}>Download Stores</Button>
    </RecentlyAddedHeader>
    <RecentlyAdded>
      <RecentStores>
        <h3>Stores</h3>
        <Table columns={storeColumns} pagination={false} dataSource={recentData && recentData.stores} />
      </RecentStores>
      <RecentCoupons>
        <h3>Coupons</h3>
        <Table columns={couponsColumns} pagination={false} dataSource={recentData && recentData.coupons} />
      </RecentCoupons>
      <RecentDeals>
        <h3>Deals</h3>
        <Table columns={dealsColumns} pagination={false} dataSource={recentData && recentData.deals} />
      </RecentDeals>
    </RecentlyAdded>
  </WrapperContainer>
}

export default Insight;
