import { BASE_URL } from "../constants"
const rootRoute = '/api/panel/deals'

export default {
    getstoredeal: `${BASE_URL}${rootRoute}/getstoredeal`,
    adddeal: `${BASE_URL}${rootRoute}/adddeal`,
    updatedeal: `${BASE_URL}${rootRoute}/updatedeal`,
    getallstores: `${BASE_URL}${rootRoute}/getallstores`,
    getallcategories: `${BASE_URL}${rootRoute}/getallcategories`,
    getdealscategory: `${BASE_URL}${rootRoute}/getdealscategory`,
    dealdelete: `${BASE_URL}${rootRoute}/dealdelete`,
    bulkdealupload: `${BASE_URL}${rootRoute}/bulkdealupload`,
    getallbanks: `${BASE_URL}${rootRoute}/getallbanks`,
    getdealsdump: `${BASE_URL}${rootRoute}/getdealsdump`
}
