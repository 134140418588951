import './App.css';
import { Provider } from 'react-redux'
import Store from "./store/store"
import "antd/dist/antd.css";

import UI from './UI'
function App() {

  let store = Store()

  return (
    <Provider store={store}>
      <UI/>
    </Provider>

  )
  
}

export default App;
