import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, AutoComplete, Select, Button, Checkbox, message } from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import useDeal from '../../../../hooks/api/deals/useDeals';

const { Option } = Select;


const DealModel = ({ modal2Visible, setModal2Visible, modalData, setTableData, searchData }) => {

    console.log(modalData)

    const [storeCode, setStoreCode] = useState(modalData.d_store_code ? modalData.d_store_code : "")
    const [expireDate, setExpireDate] = useState(modalData.d_expire_date ? moment(modalData.d_expire_date).toDate() : new Date)
    const [details, setDetails] = useState(modalData.d_details ? modalData.d_details : "")
    const [shortTitle, setShortTitle] = useState(modalData.d_short_title ? modalData.d_short_title : "")
    const [verified, setVerified] = useState(modalData.d_is_coupon_verified ? modalData.d_is_coupon_verified : true)
    const [mainText, setMainText] = useState(modalData.d_main_text ? modalData.d_main_text : "")
    const [newUser, setNewUser] = useState(modalData.d_new_user ? modalData.d_new_user : false)
    const [displayOrder, setDisplayOrder] = useState(modalData.d_display_order ? modalData.d_display_order : 0)
    const [affliateUrl, setAffliateUrl] = useState(modalData.d_affliate_url ? modalData.d_affliate_url : null)
    const [redirectURl, setRedirectUrl] = useState(modalData.d_redirect_url ? modalData.d_redirect_url : false)

    const [categoryList, setCategoryList] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [bankList, setBankList] = useState([])
    const [selectedBank, setSelectedBank] = useState([])
    const [defaultCategory, setDefaultCategory] = useState(modalData.defaultValue ? modalData.defaultValue : [])
    const [defaultBank, setDefaultBank] = useState(modalData.defaultValue ? modalData.defaultValue : [])
    const [lastUpdate, setLastUpdate] = useState(modalData.d_last_update_on ? modalData.d_last_update_on : "")

    const [search, setSearch] = useState(searchData ? searchData : [])

    const { getAllCategories, getAllBanks, getstoredeals, addDeal, updateDeal } = useDeal()

    const children1 = [];

    const children2 = [];

    useEffect(async () => {
        let categories = await getAllCategories()
        categories.data && categories.data.forEach((item, i) => {
            children1.push(<Option key={item.category_code}>{item.category_code}</Option>);

        })
        setCategoryList(children1)

        let banks = await getAllBanks()
        banks.data && banks.data.forEach((item, i) => {
            children2.push(<Option key={item.bank_code}>{item.bank_code}</Option>);

        })
        setBankList(children2)
    }, [])


    function handleCategoryChange(value) {
        setSelectedCategory(value)
    }

    function handleBankChange(value) {
        setSelectedCategory(value)
    }


    const onSearch = (e) => {
        let input = e.toLowerCase();
        setStoreCode(input)
        if (input.length > 0) {
            let filteredStoresData = searchData.filter((row, index) => {
                if ((row.value).toLowerCase().includes(input)) {
                    return row
                }
            })
            setSearch(filteredStoresData);
        } else {
            setSearch(searchData);
        }
    }

    const onSelect = (e) => {
        setStoreCode(e)
    }


    const handleSubmit = async (e) => {
        let data = {
            d_store_code: storeCode,
            d_expire_date: moment(expireDate).format("YYYY-MM-DD"),
            d_details: details,
            d_short_title: shortTitle,
            d_is_coupon_verified: verified,
            d_main_text: mainText,
            d_new_user: newUser,
            d_display_order: displayOrder,
            d_affliate_url: affliateUrl,
            d_redirect_url: affliateUrl && affliateUrl != null ? redirectURl : false,
            categoryList: selectedCategory,
            bankList: selectedBank,
            d_last_update_on: new Date().toISOString().slice(0, 10)
        }

        let couponResult;
        if (modalData.d_store_code) {
            data.d_id = modalData.d_id
            couponResult = await updateDeal(JSON.stringify(data))
        } else {
            couponResult = await addDeal(JSON.stringify(data))

        }

        if (couponResult && couponResult.success) {
            message.success("Successfully added")
            let coupons = await getstoredeals(JSON.stringify({ d_store_code: storeCode }))
            setTableData(coupons.data)
        }

        setModal2Visible(false)
    }

    return (
        <Modal
            title={modalData.d_store_code ? `${modalData.d_store_code}  Data` : "Add New Deal"}
            centered
            visible={modal2Visible}
            destroyOnClose={true}
            footer={null}
            onOk={() => setModal2Visible(false)}
            onCancel={() => setModal2Visible(false)}
            width={1000}>

            <Form name="basic" labelCol={{ span: 4, }} wrapperCol={{ span: 20, }} onFinish={handleSubmit} autoComplete="off"
                fields={[
                    {
                        name: ["Store Code"],
                        value: storeCode,
                    },

                    {
                        name: ["details"],
                        value: details
                    },
                    {
                        name: ["Short Title"],
                        value: shortTitle
                    },
                    {
                        name: ["main text"],
                        value: mainText
                    },
                    {
                        name: ["verified"],
                        value: verified
                    },
                    {
                        name: ["newUser"],
                        value: newUser
                    }, {
                        name: ["displayOrder"],
                        value: displayOrder
                    },
                    {
                        name: ["Affliate Url"],
                        value: affliateUrl
                    }, {
                        name: ["Redirect Url"],
                        value: redirectURl
                    },
                ]}
            >

                <Form.Item
                    label="Store Code"
                    name="Store Code"
                    style={{ marginTop: "10px" }}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Store Code!',
                        },
                    ]}
                >
                    {/* <Input name="sc" value={storeCode} onChange={(e) => { setStoreCode(e.target.value) }} /> */}
                    <AutoComplete
                        dropdownClassName="certain-category-search-dropdown"
                        dropdownMatchSelectWidth={200}
                        options={search}
                        style={{ width: "100%" }}
                        value={storeCode}
                        onSearch={onSearch}
                        onSelect={onSelect}

                    ></AutoComplete>
                </Form.Item>




                <Form.Item name="details" label="details" >
                    <Input value={details} onChange={(e) => { setDetails(e.target.value) }} />
                </Form.Item>

                <Form.Item label="expired date" name="expired" >
                    <DatePicker selected={expireDate} onChange={(date) => setExpireDate(date)} dateFormat="yyyy/MM/dd" />

                </Form.Item>

                <Form.Item
                    label="Short Title"
                    name="Short Title"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter short title!',
                        },
                    ]}
                >
                    <Input value={shortTitle} onChange={(e) => { setShortTitle(e.target.value) }} />
                </Form.Item>
                <Form.Item
                    label="main text"
                    name="main text"
                >
                    <Input value={mainText} onChange={(e) => { setMainText(e.target.value) }} />
                </Form.Item>

                <Form.Item label="displayOrder" name="displayOrder" rules={[
                    {
                        required: true,
                        message: 'Please enter displayOrder!',
                    },
                ]} >
                    <Input type="number" value={displayOrder} onChange={(e) => { setDisplayOrder(e.target.value) }} />
                </Form.Item>

                <Form.Item label="Categories">
                    <Select mode="multiple" style={{ width: '100%' }} defaultValue={defaultCategory} placeholder="Add Categories" onChange={handleCategoryChange}>
                        {categoryList}
                    </Select>
                </Form.Item>

                <Form.Item label="Banks">
                    <Select mode="multiple" style={{ width: '100%' }} defaultValue={defaultBank} placeholder="Add Banks" onChange={handleBankChange}>
                        {bankList}
                    </Select>
                </Form.Item>

                <Form.Item label="Affliate Url" name="Affliate Url" >
                    <Input value={affliateUrl} onChange={(e) => { setAffliateUrl(e.target.value) }} />
                </Form.Item>

                <Form.Item label="Coupon Verified" name="verified" >
                    <Checkbox
                        checked={verified}
                        onChange={(e) => { setVerified(e.target.checked) }}
                    >
                    </Checkbox>
                </Form.Item>

                <Form.Item label="New User" name="newUser" >
                    <Checkbox
                        checked={newUser}
                        onChange={(e) => { setNewUser(e.target.checked) }}
                    >
                    </Checkbox>
                </Form.Item>



                <Form.Item label="Affliate Url" name="Redirect Url" >
                    <Checkbox
                        checked={affliateUrl && affliateUrl != null ? redirectURl : false}
                        disabled={affliateUrl && affliateUrl != null ? false : true}
                        onChange={(e) => { setRedirectUrl(e.target.checked) }}
                    >
                        {/* Affliate Url */}
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 12,
                        span: 16,
                    }}>
                    <Button type="primary" htmlType="submit" /*onClick={(e)=>{e.preventDefault()}}*/>
                        Submit
                    </Button>
                </Form.Item>


            </Form>

        </Modal>
    )

}

export default DealModel;
