/* ----------- AUTH ----------- */
export const SET_AUTH = 'SET_AUTH';
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_AUTH_STATE = 'SET_AUTH_STATE'

export const RESET_STORE = 'RESET_STORE';


/* ---------- STORES ---------- */
export const SET_STORES = 'SET_STORES';

/* ---------- COUPONS ---------- */
export const SET_COUPONS = 'SET_COUPONS';

/* ---------- DEALS ---------- */
export const SET_DEALS = 'SET_DEALS';
