import { Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import React, { useState } from "react";
import { Layout, Menu, Button } from "antd";
import { connect } from "react-redux";
import { useLogout } from "../../hooks/api/auth/useLogout";
import CWlogo from "../../media/cwlogo.svg"
import {
     MenuUnfoldOutlined,
     MenuFoldOutlined,
     AreaChartOutlined,
     CommentOutlined,
     UserAddOutlined,
} from "@ant-design/icons";
import StoreIcon from '../../media/store.png';
import _ from "lodash";
const { Header, Sider, Content } = Layout;

const MENU = [
     {
          name: "Insights",
          url: "/insight",
          // icon: AreaChartOutlined,
          position: "fixed",
     },
     {
          name: "Desktop Banners",
          url: "/desktopbanners",
          // icon: UserAddOutlined,
     },
     {
          name: "Mobile Banners",
          url: "/mobilebanners",
          // icon: UserAddOutlined,
     },
     {
          name: "Stores",
          url: "/stores",
          // icon: UserAddOutlined,
     },
     {
          name: "Category",
          url: "/categories",
     },
     {
          name: "Banks",
          url: "/banks",
     },
     {
          name: "Upload Image",
          url: "/uploadimage",
          // icon: UserAddOutlined,
     },
     {
          name: "Coupons",
          url: "/coupons",
     },
     {
          name: "Deals",
          url: "/deals",
     },
     {
          name: "Blogs",
          url: "/blogs",
     },
     {
          name: "Notification",
          url: "/notification"
     }
     //  {
     //       name: "Coupons",
     //       url: "/profile",
     //       icon: UserAddOutlined,
     //  },
];




const LayoutCover = (props) => {
     const { children } = props;
     const [collapsed, setCollapsed] = useState(false);

     const history = useNavigate()


     const location = useLocation();

     const toggle = () => {
          collapsed ? setCollapsed(false) : setCollapsed(true);
     };

     const createIcon = (string) => {
          let component = React.createElement(string);
          return component;
     };

     const Logout = () => {
          localStorage.removeItem("token");
          history('/', { replace: false })
     }

     return (
          location.pathname !== "/" ?
               <Layout>

                    <Sider trigger={null} collapsible collapsed={collapsed}>
                         <Menu
                              theme="dark"
                              mode="inline"
                              defaultSelectedKeys={["0"]}
                              className="sidebar"
                         >
                              <div className="logo" style={{ margin: "13px", height: "38px", display: "flex", justifyContent: "center" }}>
                                   <img src={CWlogo} alt="Logo" style={{ height: "-webkit-fill-available" }} />
                              </div>
                              {_.map(MENU, (data, index) => {
                                   return (
                                        <Menu.Item key={index}>
                                             <Link to={data.url}>
                                                  {data.icon && createIcon(data.icon)}
                                                  <span>{data.name}</span>
                                             </Link>
                                        </Menu.Item>
                                   );
                              })}
                         </Menu>
                    </Sider>
                    <Layout className="site-layout">
                         <Header
                              className="site-layout-background fl-right"
                              style={{ padding: 20, zIndex: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}
                         >
                              {React.createElement(
                                   collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                                   {
                                        className: "trigger",
                                        onClick: toggle,
                                   }
                              )}
                              <Button type="primary" className="cus-btn" onClick={Logout} >
                                   Logout
                              </Button>
                         </Header>
                         <Content
                              className="site-layout-background"
                              style={{
                                   maxHeight: "calc(100vh - 80px)",
                                   overflowY: "scroll",
                                   // margin: "24px 6px",
                                   // padding: 24,
                                   // height: "100%",
                                   // minHeight: "580px",
                                   // overflow: "hidden",
                              }}
                         >
                              {children}
                         </Content>
                    </Layout>
               </Layout>
               :
               <Layout>
                    {children}
               </Layout>
     );
};

export default connect(null, {})(LayoutCover);
