import styled from "styled-components";

export const HeaderContent = styled.div`

    height: 60px;
    width: 200px;
    color: white;
    font-size: 24px !important;
    font-weight: 600 !important;
    margin-top: 10px;
    margin-left: 20px;
`;

export const BodyContainer = styled.div`
    height: 400px;
    width: 1050px;   
    margin-left: 20px;
    background-color: #f7f7f7;
`;

export const Title = styled.div`
   display: flex;
   flex-direction: row;
   margin-left: 20px;
   input{
   margin-left: 23px;
   width: 400px;
    height: 40px;
    margin-top: 33px;
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 10px;
 }
 h1{
    margin-left: 163px;
    margin-top: 38px;
    font-weight: 500 !important;
    font-size: 18px !important;
 }
 input:focus{
    outline: none !important;
    border-color: #1890ff;
    box-shadow: 0 0 5px #1890ff;
 }
`;


export const Description = styled.div`
   display: flex;
   flex-direction: row;
   margin-left: 20px;
   input{
   margin-left: 24px;
   width: 400px;
    height: 40px;
    margin-top: 18px;
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 10px;
 }
 input:focus{
    outline: none !important;
    border-color: #1890ff;
    box-shadow: 0 0 5px #1890ff;
 }
 h1{
    margin-left: 105px;
    margin-top: 25px;
    font-family: 'Poppins',sans-serif !important;
    font-weight: 500 !important;
    font-size: 18px !important;
 }
`;

export const RedirectUrl = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

   display: flex;
   flex-direction: row;
   margin-left: 20px;
   input{
   margin-left: 24px;
   width: 400px;
    height: 40px;
    margin-top: 14px;
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 10px;
 }
 input:focus{
    outline: none !important;
    border-color: #1890ff;
    box-shadow: 0 0 5px #1890ff;
 }
 h1{
    margin-left: 87px;
    margin-top: 22px;
    font-family: 'Poppins',sans-serif !important;
    font-weight: 500 !important;
    font-size: 18px !important;
 }
`;

export const ImageUrl = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

   display: flex;
   flex-direction: row;
   margin-left: 17px;
   input{
   margin-left: 24px;
   width: 400px;
    height: 40px;
    margin-top: 15px;
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 10px;
 }
 h1{
    margin-left: 106px;
    margin-top: 22px;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Poppins',sans-serif !important;
    font-weight: 500 !important;
    font-size: 18px !important;
 }
 input:focus{
    outline: none !important;
    border-color: #1890ff;
    box-shadow: 0 0 5px #1890ff;
 }
`;

export const SubmitBtn = styled.div`
    background-color: #1890ff;
    border: none;
    color: white;
    padding: 8px 19px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-left: 388px;
    margin-top: 35px;
`;

export const TitleValidation = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
display: flex;
   flex-direction: row;
   margin-left: 20px;
   input{
   margin-left: 23px;
   width: 400px;
    height: 40px;
    margin-top: 33px;
    border: 1px solid red;
    border-radius: 2px;
    padding: 10px;
 }
 h1{
    margin-left: 163px;
    margin-top: 38px;
    font-weight: 500 !important;
    font-size: 18px !important;
 }
 p{
   color: red;
    margin-left: 10px;
    margin-top: 39px;
 }
 input:focus{
    outline: none !important;
 }
`;

export const DescriptionValidation = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
display: flex;
   flex-direction: row;
   margin-left: 20px;
   input{
   margin-left: 24px;
   width: 400px;
    height: 40px;
    margin-top: 18px;
    border: 1px solid red;
    border-radius: 2px;
    padding: 10px;
 }
 input:focus{
    outline: none;
 }
 h1{
    margin-left: 105px;
    margin-top: 25px;
    font-family: 'Poppins',sans-serif !important;
    font-weight: 500 !important;
    font-size: 18px !important;
 }
 p{
   color: red;
    margin-left: 10px;
    margin-top: 25px;
 }
`;
export const RedirectUrlValidation = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
display: flex;
   flex-direction: row;
   margin-left: 20px;
   input{
   margin-left: 24px;
   width: 400px;
    height: 40px;
    margin-top: 14px;
    border: 1px solid red;
    border-radius: 2px;
    padding: 10px;
 }
 input:focus{
    outline: none;
 }
 h1{
    margin-left: 87px;
    margin-top: 22px;
    font-family: 'Poppins',sans-serif !important;
    font-weight: 500 !important;
    font-size: 18px !important;
 }
 p{
   color: red;
    margin-left: 10px;
    margin-top: 21px;
 }
`;

export const ImageUrlValidation = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

display: flex;
   flex-direction: row;
   margin-left: 17px;
   input{
   margin-left: 24px;
   width: 400px;
    height: 40px;
    margin-top: 15px;
    border: 1px solid red;
    border-radius: 2px;
    padding: 10px;
 }
 h1{
    margin-left: 106px;
    margin-top: 22px;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Poppins',sans-serif !important;
    font-weight: 500 !important;
    font-size: 18px !important;
 }
 input:focus{
    outline: none;
 }
 p{
   color: red;
    margin-left: 10px;
    margin-top: 21px;
 }
`;