import { Upload, message,Button,Typography } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { PageHeader } from 'antd';

import {Container,Wrapper,ImageContainer,ResultContainer} from "./index.styled"
import useImage from "../../../hooks/api/image/useImage"


function ImageUpload(){

    const [loading,setLoading] = useState(false)
    const [imageUrl,setImageUrl] = useState(null)
    const [oriImageUrl,setOriImageUrl] = useState(null)
    const [showResult, setShowResult] = useState(false)
    const [resultUrl,setResultUrl] = useState(null)

    const {  Paragraph } = Typography;


    const {uploadImageFunc} = useImage()

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        // return isJpgOrPng && isLt2M;
        return true
    }

    const handleChange = info => {
        console.log(info)
        getBase64(info.file.originFileObj, imageUrl =>
            {

                setImageUrl(imageUrl);
                setOriImageUrl(info.file.originFileObj)
            })
        // if (info.file.status === 'uploading') {
        //     console.log("stage 1")
        //     setLoading(true)
        //   return;
        // }
        // if (info.file.status === 'done') {
        //   // Get this url from response in real world.
        //   getBase64(info.file.originFileObj, imageUrl =>
        //     {
        //         console.log("stage 2")

        //         setImageUrl(imageUrl);
        //         setLoading(false)
        //     }
            
            
        //   );
        // }
    };

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    
    const uploadImage = async() =>{
        setLoading(true)

        var bodyFormData = new FormData();
        bodyFormData.append('image',oriImageUrl ); 
        let result = await uploadImageFunc(bodyFormData)
        setResultUrl(result.image)
        setLoading(false)
        setShowResult(true)

        
    }

    function copyTextToClipboard(text) {
       
        navigator.clipboard.writeText(text).then(function() {
            message.success("Copied!")
        }, function(err) {
            message.error("Error in copy")
        });
      }

    return(
        <div>
            <PageHeader
            className="site-page-header"
            title="Upload Image"
            // subTitle="This is a subtitle"
        />
            <Container>
                <Wrapper>
                    <ImageContainer>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            style={{width:"fit-content"}}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                        <Button type="primary" htmlType="submit" onClick={(e)=>{e.preventDefault();uploadImage()}}>{loading ? <LoadingOutlined /> : "Submit"}</Button>
                    </ImageContainer>
                    {showResult && <ResultContainer>
                        <Paragraph>
                            <blockquote style={{border:"4px solid rgba(100, 100, 100, 0.2)",padding:"10px",paddingTop:"5px",paddingBottom:"5px",marginTop:"0px"}}>{resultUrl}</blockquote>
                        </Paragraph>
                        <Button type="primary" size="large" onClick={(e)=>{e.preventDefault();copyTextToClipboard(resultUrl)}}>Copy</Button>

                    </ResultContainer>}
                </Wrapper>
                
            </Container>
            
            
        </div>
    
    )
}


export default ImageUpload