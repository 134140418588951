import styled from "styled-components";


export const Wrapper = styled.div`

`;

export const BannerCard = styled.div`
width: 99%;
display: flex;
margin: 15px;
box-shadow: 0px 0px 5px 0px grey;
border-radius: 4px;
padding: 5px;
`;

export const Container = styled.div`

`;

export const Left = styled.div`
width: 65%;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
    img{
        margin: 5px;
        width: 98%;
    }
`;

export const Right = styled.div`
width: 35%;
padding: 5px;
overflow: hidden;
h3{
    font-size: 18px;
    color: black;
}
p{
    font-size: 16px;
    color: #575757;
}
`;

export const UrlWrapper = styled.div`
p{
    box-shadow: 0px 0px 2px 0px grey;
    border-radius: 2px;
    padding: 5px;
    word-break: break-all;
    white-space: normal;
}
`;

export const Position = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        box-shadow: 0px 0px 2px 0px grey;
        border-radius: 2px;
        padding: 5px;
        color: #575757;
    }
`;

export const UrlContainer = styled.div`

`;

export const Edit = styled.div`
    margin-right: 10px;
`;

export const EditButton = styled.button`
    width: 75px;
    outline: none;
    border: none;
    box-shadow: 0px 0px 3px 0px grey;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    background: #e00e3f;
    font-weight: 600;
    cursor: pointer;
`;