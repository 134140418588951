import React, { useEffect, useState } from "react"
import { Table, Tag, Space, Popconfirm, Button, Input } from 'antd';
import useCategory from "../../../hooks/api/categories";
import CategoryModel from "../ReUsable/Model/category";

import { Container, TopHeader } from "./index.styled";

const { Search } = Input;

let newData = { data: [] };


const Categories = () => {

    let { getallCategories, addnewCategory, updatecategory } = useCategory()

    const [modal2Visible, setModal2Visible] = useState(false)
    const [originalData, setOriginalData] = useState([])
    const [tableData, setTableData] = useState([])
    const [modalData, setModalData] = useState({})



    const columns = [
        {
            title: 'Category Name',
            dataIndex: 'ct_display_name',
            key: 'ct_display_name',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Store Code',
            dataIndex: 'ct_code',
            key: 'ct_code',
        },
        {
            title: 'Store Score',
            dataIndex: 'ct_score',
            key: 'ct_score',
        },

        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button id={record.s_id} onClick={() => { onEdit(record) }}>Edit</Button>
                </Space>
            ),
        },
    ];



    const onEdit = (record) => {
        setModalData(record);
        setModal2Visible(true);
    }


    useEffect(async () => {
        newData = await getallCategories();
        setTableData(newData.data)
        setOriginalData(newData.data)
    }, [])

    const SearchStores = () => {
        let input = document.querySelector(".ant-input").value.toLowerCase();
        if (input.length > 0) {
            let filteredStoresData = originalData.filter((row, index) => {
                if ((row.ct_display_name).toLocaleLowerCase().includes(input)) {
                    return row
                }
            })
            setTableData(filteredStoresData);
        } else {
            setTableData(newData.data)
        }
    }

    return (
        <Container>
            <TopHeader><Search placeholder="Search Here" className="search-input" onChange={() => { SearchStores() }} enterButton /><Button onClick={() => { setModalData({}); setModal2Visible(true) }}>Add Category</Button></TopHeader>
            <Table columns={columns} dataSource={tableData} />
            <CategoryModel modal2Visible={modal2Visible} modalData={modalData} setModal2Visible={setModal2Visible} setTableData={setTableData} setOriginalData={setOriginalData} />
        </Container>
    )
}

export default Categories;