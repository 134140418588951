

import AuthEndpoints from '../../../config/endpoints/banks';
import useRequest from '../index';


const { getBanksList,updateBankURL,addNewBank,deleteBankUrl,bulkbankupload } = AuthEndpoints;

const useBanks = (data) => {


    /* LOCAL STATES */
    

    /* HOOKS */
    const {get,post} = useRequest()

   

    const getBanks = async () => get(getBanksList)
    const updateBank = async (data) => post(updateBankURL,data)
    const addBank = async (data) => post(addNewBank,data)
    const deleteBank = async (data) => post(deleteBankUrl,data)
    const bulkBankupload = async (data) => post(bulkbankupload,data)

   

    return { getBanks,updateBank,addBank,deleteBank,bulkBankupload }

}

export default useBanks