
import {useDispatch} from 'react-redux';

import useRequest from '../index';

import AuthEndpoints from '../../../config/endpoints/auth';

const { loginUser,getCurrentUser } = AuthEndpoints;



const useLogin = (data) => {

   

    /* HOOKS */
    const {post} = useRequest()

    /* HANDLE LOGIN SUBMIT */
    const onSubmit = async (data) => post(loginUser,data)
    


    return { onSubmit }

}

export default useLogin