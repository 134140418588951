// import { applyMiddleware, createStore } from 'redux'
// import thunkMiddleware from 'redux-thunk'
// import { composeWithDevTools } from 'redux-devtools-extension'

// // import monitorReducersEnhancer from './enhancers/monitorReducers'
// // import { createLogger } from "redux-logger";
// import rootReducer from '../statemanagement/reducers'

// export default function configureStore(preloadedState) {
//   const middlewares = [ thunkMiddleware]
//   const middlewareEnhancer = applyMiddleware(...middlewares)

//   const enhancers = [middlewareEnhancer]
//   const composedEnhancers = composeWithDevTools(...enhancers)

//   const store = createStore(rootReducer, preloadedState, composedEnhancers)

//   return store
// }

// /* NPM IMPORTS */
// import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage/session' // defaults to localStorage for web/* CUSTOM IMPORTS */
// import rootReducer from './stateManagement/reducers';
// const initState = {}
// const middleWare = [thunk]
// const persistConfig = {
// key: 'root',
// storage,
// whitelist: ['search', 'payment', 'feature'],
// }
// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const composeEnhancers = process.env.REACT_APP_NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;export let store = createStore(
// persistedReducer,
// // rootReducer,
// composeEnhancers(
// applyMiddleware(thunk)
// )
// );export let persistor = persistStore(store, null, () => {})

import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import { createLogger } from "redux-logger";
import rootReducer from '../statemanagement/reducers'

export default function configureStore(preloadedState) {
  const middlewares = [ thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}

// import { createStore,applyMiddleware } from "redux";
// import thunkMiddleware from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
// import { createLogger } from "redux-logger";
// import rootReducer from "../statemanagement/reducers";

// const ConfigreStore = (initialState = {}) => {
//     const middleware = [thunkMiddleware];
//     if(typeof window === "undefined"){
//         console.log("If")
//         return middleware.push(createLogger({ collapsed: true}));
//     }else{
//         console.log("Else")
//         return createStore(
//             rootReducer,
//             {
//               auth:{
//                 user : {},
//                 auth : false,
//                 token : localStorage.getItem('token'),
//                 status: "loaded"
//             },
//               stores: [],
//               coupons: {},
//               deals: {}
//             },
//             composeWithDevTools(applyMiddleware(...middleware))
//         )
//     }
// }

// export default ConfigreStore;