import { combineReducers } from "redux";
import authReducer from "./authReducer";
import storesReducer from "./storesReducer";
import couponsReducer from "./couponsReducer";
import dealsReducer from "./dealsReducer";

const globalReducer = combineReducers({
  auth: authReducer,
  stores: storesReducer,
  coupons: couponsReducer,
  deals: dealsReducer
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }

  return globalReducer(state, action);
};
export default rootReducer;


