import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


import useBlogs from '../../../../hooks/api/blogs/useBlogs';


const BannerModal = ({ modal2Visible, setModal2Visible, modalData, setTableData }) => {
    const [redirectUrl, setRedirectUrl] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [position, setPosition] = useState("");
    const [title, setTitle] = useState("");
    const [displayDate, setDisplayDate] = useState(modalData.blogs_display_date ? moment(modalData.blogs_display_date).toDate() : new Date)

    const { getBlogs, updateBlogs } = useBlogs()


    const handleSubmit = async () => {
        // e.preventDefault();

        let data = {
            "img_url": imgUrl,
            "link_url": redirectUrl,
            "position_no": position,
            "update_date": new Date().toISOString().slice(0, 10),
            "blog_id": modalData.blogs_id,
            "blog_title": title,
            "display_date": moment(displayDate).format("YYYY-MM-DD")
        }
        // console.log(data)

        let result;

        result = await updateBlogs(JSON.stringify(data))

        setModal2Visible(false)
        let newData = await getBlogs();
        setTableData(newData.data)

        if (result.success) {
            message.success("Saved!!");
        }

    }

    useEffect(() => {
        setImgUrl(modalData.blogs_image_url ? modalData.blogs_image_url : "");
        setRedirectUrl(modalData.blogs_link_url ? modalData.blogs_link_url : "")
        setTitle(modalData.blogs_title ? modalData.blogs_title : "")
        setPosition(modalData.blogs_order ? modalData.blogs_order : 1)
    }, [modalData])

    return <Modal
        title={"Edit Banner Data"}
        centered
        visible={modal2Visible}
        destroyOnClose={true}
        footer={null}
        onOk={() => setModal2Visible(false)}
        onCancel={() => setModal2Visible(false)}
        width={1000}>
        <br />
        <Form name="basic" labelCol={{ span: 4, }} wrapperCol={{ span: 20, }} onFinish={handleSubmit} autoComplete="off"
            fields={[
                {
                    name: ["Redirect Url"],
                    value: redirectUrl,
                },
                {
                    name: ["Image Url"],
                    value: imgUrl
                },
                {
                    name: ["Position"],
                    value: position
                },
                {
                    name: ["title"],
                    value: title
                },
                {
                    name: ["display date"],
                    value: displayDate
                }
            ]}>

            <Form.Item wrapperCol={{
                offset: 8,
            }}>
                <img src={imgUrl} width="50%" />
            </Form.Item>

            <Form.Item label="Display Date" name="display date" >
                <DatePicker selected={displayDate} onChange={(date) => setDisplayDate(date)} dateFormat="yyyy/MM/dd" />

            </Form.Item>

            <Form.Item
                label="Redirect Url"
                name="Redirect Url"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Redirect Url!',
                    },
                ]}

            >
                <Input name="sdn" value={redirectUrl} onChange={(e) => { setRedirectUrl(e.target.value) }} />
            </Form.Item>

            <Form.Item
                label="Image Url"
                name="Image Url"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Image Url!',
                    },
                ]}>
                <Input value={imgUrl} onChange={(e) => { setImgUrl(e.target.value) }} />
            </Form.Item>

            <Form.Item
                label="Title"
                name="title"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Title Url!',
                    },
                ]}>
                <Input value={title} onChange={(e) => { setTitle(e.target.value) }} />
            </Form.Item>

            <Form.Item name="Position" label="Position" rules={[
                {
                    required: true,
                    message: 'Please enter Position!',
                },
            ]}>
                <Input type="number" value={position} onChange={(e) => { setPosition(e.target.value) }} />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 12,
                    span: 16,
                }}>
                <Button type="primary" htmlType="submit" /*onClick={(e)=>{e.preventDefault()}}*/>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </Modal>

}

export default BannerModal;