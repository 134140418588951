import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';
// import { Editor } from "react-draft-wysiwyg";

import useBanks from '../../../../hooks/api/banks';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { TextArea } = Input;


const UIModal = ({ modal2Visible, setModal2Visible, modalData, setTableData, setOriginalData }) => {
    const [bankName, setBankName] = useState(null)
    const [bankCode, setBankCode] = useState(null)
    const [bankScore, setBankScore] = useState(null)
    const [seoTitle, setSeoTitle] = useState(null)
    const [seoDescription, setSeoDescription] = useState(null)
    const [description, setDescription] = useState(null)
    const [relatedDescription, setRelatedDescription] = useState(null)
    const [redirectUrl, setRedirectUrl] = useState(null)
    const [scriptTag, setScriptTag] = useState(null)
    const [h1, setH1] = useState(null)
    const [bgcolor, setBgcolor] = useState(null)
    const [imgUrl, setImgUrl] = useState(null)
    const [mobileImgUrl, setmobileImgUrl] = useState(null)

    const { updateBank, getBanks, addBank } = useBanks()


    const handleSubmit = async () => {
        // e.preventDefault();

        let data = {
            b_display_name: bankName,
            b_code: bankCode,
            b_description_text: description,
            b_related_description: relatedDescription,
            b_seo_description: seoDescription,
            b_seo_title: seoTitle,
            b_h1: h1,
            b_image_url: imgUrl,
            b_last_update_on: new Date().toISOString().slice(0, 10),
            b_score: bankScore,
            script_tag: scriptTag,
            b_bg_color: bgcolor,
            b_mobile_img_url: mobileImgUrl

        }

        let result;
        if (!modalData.b_display_name) {
            result = await addBank(JSON.stringify(data))

        } else {
            result = await updateBank(JSON.stringify(data))
        }


        setModal2Visible(false)
        let newData = await getBanks();
        setTableData(newData.data)
        setOriginalData(newData.data)
        if (result.success) {
            message.success("Saved!!");
        }

    }

    const onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    function process(str) {
        var div = document.createElement('div');
        div.innerHTML = str.trim();

        return format(div, 0).innerHTML;
    }

    function format(node, level) {
        var indentBefore = new Array(level++ + 1).join('  '),
            indentAfter = new Array(level - 1).join('  '),
            textNode;

        for (var i = 0; i < node.children.length; i++) {
            textNode = document.createTextNode('\n' + indentBefore);
            node.insertBefore(textNode, node.children[i]);

            format(node.children[i], level);

            if (node.lastElementChild == node.children[i]) {
                textNode = document.createTextNode('\n' + indentAfter);
                node.appendChild(textNode);
            }
        }

        return node;
    }

    useEffect(() => {
        setBankName(modalData.b_display_name && modalData.b_display_name);
        setBankCode(modalData.b_code ? modalData.b_code : "");
        setBankScore(modalData.b_score ? modalData.b_score : "");
        setSeoTitle(modalData.b_seo_title ? modalData.b_seo_title : "");
        setSeoDescription(modalData.b_seo_description ? modalData.b_seo_description : "");
        setDescription(modalData.b_description_text ? process(modalData.b_description_text) : "");
        setRelatedDescription(modalData.b_related_description ? modalData.b_related_description : "");
        setH1(modalData.b_h1 ? modalData.b_h1 : "")
        setImgUrl(modalData.b_image_url ? modalData.b_image_url : "")
        setScriptTag(modalData.script_tag ? modalData.script_tag : "")
        setBgcolor(modalData.b_bg_color ? modalData.b_bg_color : null)
        setmobileImgUrl(modalData.b_mobile_img_url ? modalData.b_mobile_img_url : null)
    }, [modalData])

    return <Modal
        title={modalData.b_display_name ? `${modalData.b_display_name} Bank Data` : "Add New Bank"}
        centered
        visible={modal2Visible}
        destroyOnClose={true}
        footer={null}
        onOk={() => setModal2Visible(false)}
        onCancel={() => setModal2Visible(false)}
        width={"80%"}>

        <br />
        <Form name="basic" labelCol={{ span: 4, }} wrapperCol={{ span: 20, }} onFinish={handleSubmit} autoComplete="off"
            fields={[
                {
                    name: ["Bank Display Name"],
                    value: bankName,
                },
                {
                    name: ["Bank Code"],
                    value: bankCode
                },
                {
                    name: ["Bank Score"],
                    value: bankScore
                },
                {
                    name: ["SEO Title"],
                    value: seoTitle
                },
                {
                    name: ["SEO Description"],
                    value: seoDescription
                },
                {
                    name: ["Description"],
                    value: description
                },
                {
                    name: ["Related Description"],
                    value: relatedDescription
                },
                {
                    name: ["Image Url"],
                    value: imgUrl
                },
                {
                    name: ["Mobile Image Url"],
                    value: mobileImgUrl
                },
                {
                    name: ["h1"],
                    value: h1
                },
                {
                    name: ["scripttag"],
                    value: scriptTag
                },
                {
                    name: ["bgcolor"],
                    value: bgcolor
                },
            ]}>
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "50%" }}>
                    <Form.Item
                        label="Bank Display Name"
                        name="Bank Display Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Bank Display Name!',
                            },
                        ]}

                    >
                        <Input name="sdn" value={bankName} onChange={(e) => { setBankName(e.target.value) }} />
                    </Form.Item>

                    <Form.Item
                        label="Bank Code"
                        name="Bank Code"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Bank Code!',
                            },
                        ]}>
                        <Input value={bankCode} onChange={(e) => { setBankCode(e.target.value) }} />
                    </Form.Item>

                    <Form.Item name="Bank Score" label="Bank Score" rules={[
                        {
                            required: true,
                            message: 'Please enter Bank Score!',
                        },
                    ]}>
                        <Input type="number" value={bankScore} onChange={(e) => { setBankScore(e.target.value) }} />
                    </Form.Item>

                    <Form.Item
                        label="SEO Title"
                        name="SEO Title"
                    >
                        <Input value={seoTitle} onChange={(e) => { setSeoTitle(e.target.value) }} />
                    </Form.Item>

                    <Form.Item
                        label="SEO Description"
                        name="SEO Description"
                    >
                        <Input value={seoDescription} onChange={(e) => { setSeoDescription(e.target.value) }} />
                    </Form.Item>



                    <Form.Item label="Related Description" name="Related Description" >
                        <Input value={relatedDescription} onChange={(e) => { setRelatedDescription(e.target.value) }} />
                    </Form.Item>


                    <Form.Item label="Image Url" name="Image Url" rules={[
                        {
                            required: true,
                            message: 'Please enter Image Url!',
                        },
                    ]}>
                        <Input value={imgUrl} onChange={(e) => { setImgUrl(e.target.value) }} />
                    </Form.Item>

                    <Form.Item label="Mobile Image Url" name="Mobile Image Url" rules={[
                        {
                            // required: true,
                            message: 'Please enter mobile Image Url!',
                        },
                    ]}>
                        <Input value={imgUrl} onChange={(e) => { setmobileImgUrl(e.target.value) }} />
                    </Form.Item>
                </div>

                <div style={{ width: "50%" }}>
                    <Form.Item label="h1" name="h1" rules={[
                        {
                            required: true,
                            message: 'Please enter h1!',
                        },
                    ]}>
                        <Input value={h1} onChange={(e) => { setH1(e.target.value) }} />
                    </Form.Item>

                    <Form.Item label="bgcolor" name="bgcolor" >
                        <Input value={bgcolor} onChange={(e) => { setBgcolor(e.target.value) }} />
                    </Form.Item>

                    <Form.Item label="Add script" name="scripttag" >
                        <TextArea rows={4} value={scriptTag} onChange={(e) => { setScriptTag(e.target.value) }} />
                    </Form.Item>
                    <Form.Item label="Description" name="Description" >
                        {/* <Editor
                            editorState={description}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                        /> */}
                        <TextArea style={{ height: "400px", fontSize: "12px" }} value={description} onChange={(e) => { setDescription(e.target.value) }} />
                    </Form.Item>

                </div>

            </div>


            <Form.Item
                wrapperCol={{
                    offset: 12,
                    span: 16,
                }}>
                <Button type="primary" htmlType="submit" /*onClick={(e)=>{e.preventDefault()}}*/>
                    Submit
                </Button>
            </Form.Item>


        </Form>
    </Modal>

}

export default UIModal;