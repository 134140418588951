import { BASE_URL } from "../constants"
const rootRoute = '/api/panel/coupons'

export default {
    getstorecoupon: `${BASE_URL}${rootRoute}/getstorecoupon`,
    addcoupon: `${BASE_URL}${rootRoute}/addcoupon`,
    updatecoupon: `${BASE_URL}${rootRoute}/updatecoupon`,
    getallstores: `${BASE_URL}${rootRoute}/getallstores`,
    getallcategories: `${BASE_URL}${rootRoute}/getallcategories`,
    getcouponcategory: `${BASE_URL}${rootRoute}/getcouponcategories`,
    getcouponbank: `${BASE_URL}${rootRoute}/getcouponbanks`,
    deleteurl: `${BASE_URL}${rootRoute}/deletecoupon`,
    bulkcouponupload: `${BASE_URL}${rootRoute}/bulkcouponupload`,
    getallbanks: `${BASE_URL}${rootRoute}/getallBanks`,
    getcouponsdump: `${BASE_URL}${rootRoute}/getcouponsdump`
}
