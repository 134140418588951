import React, { useEffect, useState } from 'react';
import { Table, Tag, Space, Popconfirm, Button, Input } from 'antd';
import SearchIcon from '../../../media/search.png'
import BannerModel from '../ReUsable/Model/bannerModal';
import useBanners from '../../../hooks/api/banners/useBanners';
import { useSelector } from 'react-redux';
import { Wrapper, BannerCard, Container, Left, Right, UrlWrapper, Position, UrlContainer, Edit, EditButton } from './index.styled'

const DesktopBanners = () => {
    const { getBanners, deleteStore } = useBanners()

    const [tableData, setTableData] = useState([]);
    const [modal2Visible, setModal2Visible] = useState(false)
    const [modalData, setModalData] = useState({})

    const onEdit = (record) => {
        setModalData(record);
        setModal2Visible(true);
    }

    useEffect(async () => {
        let newData = await getBanners();
        console.log(newData)
        setTableData(newData.data.desktop)
    }, [])

    return <Wrapper>
        <Container>
            {tableData.map((data, key) => {
                return <BannerCard>
                    <Left>
                        <img src={data.b_img_url} />
                    </Left>
                    <Right>
                        <UrlContainer>
                            <UrlWrapper>
                                <h3>
                                    Redirect Url
                                </h3>
                                <p>
                                    {data.b_link_url}
                                </p>
                            </UrlWrapper>
                            <UrlWrapper>
                                <h3>
                                    Img Url
                                </h3>
                                <p>
                                    {data.b_img_url}
                                </p>
                            </UrlWrapper>
                        </UrlContainer>
                        <Position>
                            <div>
                                <h3>
                                    Position
                                </h3>
                                <p>
                                    {data.b_position_no}
                                </p>
                            </div>
                            <Edit>
                                <EditButton onClick={() => { onEdit(data) }}>Edit</EditButton>
                            </Edit>
                        </Position>
                    </Right>
                </BannerCard>
            })}
        </Container>
        <BannerModel modal2Visible={modal2Visible} modalData={modalData} setModal2Visible={setModal2Visible} setTableData={setTableData} />
    </Wrapper>
}

export default DesktopBanners;
