import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';

import useCategory from '../../../../hooks/api/categories';

const CategoryModel = ({ modal2Visible, setModal2Visible, modalData, setTableData, setOriginalData }) => {
    const [categoryName, setCategoryName] = useState("")
    const [categoryCode, setCategoryCode] = useState("")
    const [categoryScore, setCategoryScore] = useState("")
    const [seoTitle, setSeoTitle] = useState("")
    const [seoDescription, setSeoDescription] = useState("")
    const [description, setDescription] = useState("")
    const [relatedDescription, setRelatedDescription] = useState("")
    const [h1, setH1] = useState("")
    const [imgUrl, setImgUrl] = useState("")

    let { getallCategories, addnewCategory, updatecategory } = useCategory()

    useEffect(() => {
        setCategoryName(modalData.ct_display_name && modalData.ct_display_name);
        setCategoryCode(modalData.ct_code ? modalData.ct_code : "");
        setCategoryScore(modalData.ct_score ? modalData.ct_score : "");
        setSeoTitle(modalData.ct_seo_title ? modalData.ct_seo_title : "");
        setSeoDescription(modalData.ct_seo_description ? modalData.ct_seo_description : "");
        setDescription(modalData.ct_description_text ? modalData.ct_description_text : "");
        setRelatedDescription(modalData.ct_related_description ? modalData.ct_related_description : "");
        setH1(modalData.ct_h1 ? modalData.ct_h1 : "")
        setImgUrl(modalData.ct_image_url ? modalData.ct_image_url : "")
    }, [modalData])


    const handleSubmit = async () => {
        // e.preventDefault();

        let data = {
            ct_display_name: categoryName,
            ct_code: categoryCode,
            ct_description_text: description,
            ct_related_description: relatedDescription,
            ct_seo_description: seoDescription,
            ct_seo_title: seoTitle,
            ct_h1: h1,
            ct_image_url: imgUrl,
            ct_last_update_on: new Date().toISOString().slice(0, 10),
            ct_score: categoryScore
        }
        // console.log(data)

        let result;
        if (!modalData.ct_display_name) {
            result = await addnewCategory(JSON.stringify(data))

        } else {
            result = await updatecategory(JSON.stringify(data))
        }


        setModal2Visible(false)
        let newData = await getallCategories();
        setTableData(newData.data)
        setOriginalData(newData.data)
        if (result.success) {
            message.success("Saved!!");
        }

    }

    return <Modal
        title={modalData.ct_display_name ? `${modalData.ct_display_name} Category Data` : "Add New Category"}
        centered
        visible={modal2Visible}
        destroyOnClose={true}
        footer={null}
        onOk={() => setModal2Visible(false)}
        onCancel={() => setModal2Visible(false)}
        width={1000}>

        <br />
        <Form name="basic" labelCol={{ span: 4, }} wrapperCol={{ span: 20, }} onFinish={handleSubmit} autoComplete="off"
            fields={[
                {
                    name: ["Category Display Name"],
                    value: categoryName,
                },
                {
                    name: ["Category  Code"],
                    value: categoryCode
                },
                {
                    name: ["Category Score"],
                    value: categoryScore
                },
                {
                    name: ["SEO Title"],
                    value: seoTitle
                },
                {
                    name: ["SEO Description"],
                    value: seoDescription
                },
                {
                    name: ["Description"],
                    value: description
                },
                {
                    name: ["Related Description"],
                    value: relatedDescription
                },
                {
                    name: ["Image Url"],
                    value: imgUrl
                },
                {
                    name: ["h1"],
                    value: h1
                },
            ]}>

            <Form.Item
                label="Category Display Name"
                name="Category Display Name"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Category Display Name!',
                    },
                ]}

            >
                <Input name="sdn" value={categoryName} onChange={(e) => { setCategoryName(e.target.value) }} />
            </Form.Item>

            <Form.Item
                label="Category  Code"
                name="Category  Code"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Category  Code!',
                    },
                ]}>
                <Input value={categoryCode} onChange={(e) => { setCategoryCode(e.target.value) }} />
            </Form.Item>

            <Form.Item name="Category Score" label="Category Score" rules={[
                {
                    required: true,
                    message: 'Please enter Category Score!',
                },
            ]}>
                <Input type="number" value={categoryScore} onChange={(e) => { setCategoryScore(e.target.value) }} />
            </Form.Item>

            <Form.Item
                label="SEO Title"
                name="SEO Title"
            >
                <Input value={seoTitle} onChange={(e) => { setSeoTitle(e.target.value) }} />
            </Form.Item>

            <Form.Item
                label="SEO Description"
                name="SEO Description"
            >
                <Input value={seoDescription} onChange={(e) => { setSeoDescription(e.target.value) }} />
            </Form.Item>


            <Form.Item label="Description" name="Description" >
                <Input value={description} onChange={(e) => { setDescription(e.target.value) }} />
            </Form.Item>

            <Form.Item label="Related Description" name="Related Description" >
                <Input value={relatedDescription} onChange={(e) => { setRelatedDescription(e.target.value) }} />
            </Form.Item>
            <Form.Item label="h1" name="h1" rules={[
                {
                    required: true,
                    message: 'Please enter h1!',
                },
            ]}>
                <Input value={h1} onChange={(e) => { setH1(e.target.value) }} />
            </Form.Item>

            <Form.Item label="Image Url" name="Image Url" rules={[
                {
                    required: true,
                    message: 'Please enter Image Url!',
                },
            ]}>
                <Input value={imgUrl} onChange={(e) => { setImgUrl(e.target.value) }} />
            </Form.Item>



            <Form.Item
                wrapperCol={{
                    offset: 12,
                    span: 16,
                }}>
                <Button type="primary" htmlType="submit" /*onClick={(e)=>{e.preventDefault()}}*/>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </Modal>

}

export default CategoryModel