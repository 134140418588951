import {BASE_URL} from "../constants"
const rootRoute = '/api/panel/bank'

export default {
    getBanksList: `${BASE_URL}${rootRoute}/getallbank`,
    updateBankURL: `${BASE_URL}${rootRoute}/updatebank`,
    addNewBank: `${BASE_URL}${rootRoute}/addbank`,
    deleteBankUrl:`${BASE_URL}${rootRoute}/deletebank`,
    bulkbankupload: `${BASE_URL}${rootRoute}/bulkupdate`
}
