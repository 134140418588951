import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';


import useBanners from '../../../../hooks/api/banners/useBanners';


const BannerModal = ({ modal2Visible, setModal2Visible, modalData, setTableData }) => {
    const [redirectUrl, setRedirectUrl] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [position, setPosition] = useState("");

    const { updateBanner, getBanners } = useBanners()


    const handleSubmit = async () => {
        // e.preventDefault();

        let data = {
            "img_url": imgUrl,
            "link_url": redirectUrl,
            "position_no": position,
            "update_date": new Date().toISOString().slice(0, 10),
            "b_id": modalData.b_id,
            "screen": modalData.b_type
        }
        // console.log(data)

        let result;

        result = await updateBanner(JSON.stringify(data))

        setModal2Visible(false)
        let newData = await getBanners();

        if (modalData.b_type == "desktop") {
            setTableData(newData.data.desktop)
        } else {
            setTableData(newData.data.mobile)
        }

        if (result.success) {
            message.success("Saved!!");
        }

    }

    useEffect(() => {
        setImgUrl(modalData.b_img_url ? modalData.b_img_url : "");
        setRedirectUrl(modalData.b_link_url ? modalData.b_link_url : "")
        setPosition(modalData.b_position_no ? modalData.b_position_no : "")
    }, [modalData])

    return <Modal
        title={"Edit Banner Data"}
        centered
        visible={modal2Visible}
        destroyOnClose={true}
        footer={null}
        onOk={() => setModal2Visible(false)}
        onCancel={() => setModal2Visible(false)}
        width={1000}>
        <br />
        <Form name="basic" labelCol={{ span: 4, }} wrapperCol={{ span: 20, }} onFinish={handleSubmit} autoComplete="off"
            fields={[
                {
                    name: ["Redirect Url"],
                    value: redirectUrl,
                },
                {
                    name: ["Image Url"],
                    value: imgUrl
                },
                {
                    name: ["Position"],
                    value: position
                }
            ]}>

            <Form.Item>
                <img src={imgUrl} width="120%" />
            </Form.Item>

            <Form.Item
                label="Redirect Url"
                name="Redirect Url"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Redirect Url!',
                    },
                ]}

            >
                <Input name="sdn" value={redirectUrl} onChange={(e) => { setRedirectUrl(e.target.value) }} />
            </Form.Item>

            <Form.Item
                label="Image Url"
                name="Image Url"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Image Url!',
                    },
                ]}>
                <Input value={imgUrl} onChange={(e) => { setImgUrl(e.target.value) }} />
            </Form.Item>

            <Form.Item name="Position" label="Position" rules={[
                {
                    required: true,
                    message: 'Please enter Position!',
                },
            ]}>
                <Input type="number" value={position} onChange={(e) => { setPosition(e.target.value) }} />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 12,
                    span: 16,
                }}>
                <Button type="primary" htmlType="submit" /*onClick={(e)=>{e.preventDefault()}}*/>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </Modal>

}

export default BannerModal;