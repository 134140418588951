import React, {useState, useEffect} from 'react';
import {
    Table,
    Upload,
    Tag,
    Space,
    Popconfirm,
    Button,
    Input,
    AutoComplete,
    message
} from 'antd';
import {UploadOutlined} from '@ant-design/icons';

import {Container, TopHeader} from './index.styled'
import {ExportToCsv} from 'export-to-csv';
import CouponModel from '../ReUsable/Model/couponModal';
import useCoupons from '../../../hooks/api/coupons/useCoupons';
import {useSelector} from 'react-redux';

import moment from 'moment';

const {Search} = Input;

let searchData = []

const Coupons = () => {


    const {
        getStoreCoupons,
        getallStores,
        addCoupon,
        deleteUrl,
        getCouponCategory,
        getCouponBank,
        bulkCouponUpload
    } = useCoupons()


    const deleteText = 'Are you sure to delete this?';
    const [tableData, setTableData] = useState([])
    const [modal2Visible, setModal2Visible] = useState(false)
    const [modalData, setModalData] = useState({})

    const [options, setOptions] = useState([])




    const confirmDelete = async (record) => {

        console.log("Doneee")
        let deletedRecord = await deleteUrl(JSON.stringify({c_id: record.c_id}))

        if (deletedRecord.success) {
            message.success("Successfully Deleted");
            let coupons = await getStoreCoupons(JSON.stringify({c_store_code: record.c_store_code}))
            setTableData(coupons.data)
        }

    }

    const onEdit = async (record) => {
        let selectedCategories = await getCouponCategory(JSON.stringify({coupon_id: record.c_id}))
        let selectedBanks = await getCouponBank(JSON.stringify({coupon_id: record.c_id}))

        let defaultSelect = []
        let defaultBanksSelect = []

        if (selectedCategories.data.length == 0) {
            record.defaultValue = defaultSelect;
            record.defaultBanksValue = defaultBanksSelect
            setModalData(record);
            setModal2Visible(true);
            return
        }

        for (let i = 0; i < selectedCategories.data.length; i++) {
            defaultSelect.push(selectedCategories.data[i].c_category_code)

            if ((i + 1) == selectedCategories.data.length) {
                record.defaultValue = defaultSelect
                setModalData(record);
                setModal2Visible(true);
            }
        }
        for (let i = 0; i < selectedBanks.data.length; i++) {
            defaultBanksSelect.push(selectedBanks.data[i].c_bank_code)

            if ((i + 1) == selectedBanks.data.length) {
                record.defaultBanksValue = defaultBanksSelect
                setModalData(record);
                setModal2Visible(true);
            }
        }
    }

    const columns = [
        {
            title: 'Store Code',
            dataIndex: 'c_store_code',
            key: 'c_store_code',
            render: text => <a href={
                `https://couponswala.com/store/${text}-offers`
            }>
                {
                text.charAt(0).toUpperCase() + text.slice(1)
            }</a>
        },
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code'
        },

        {
            title: 'Short Title',
            dataIndex: 'c_short_title',
            key: 'c_short_title'
        },
        {
            title: 'Expire Date',
            key: 'c_expire_date',
            dataIndex: 'c_expire_date',
            render: text => {
                var pickedDate = moment(text).format('YYYY-MM-DD')
                pickedDate = pickedDate.split("-")
                let dateToCompare = new Date(parseInt(pickedDate[0]), parseInt(pickedDate[1] - 1), parseInt(pickedDate[2]));

                return <Tag color={
                    new Date(dateToCompare.toDateString()) >= new Date(new Date().toDateString()) ? "green" : "volcano"
                }>
                    {
                    new Date(dateToCompare.toDateString()) >= new Date(new Date().toDateString()) ? "Live" : "Expired"
                } </Tag>
            }
        }, {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button id={
                            record.s_id
                        }
                        onClick={
                            () => {
                                onEdit(record)
                            }
                    }>Edit</Button>
                    <Popconfirm placement="topRight"
                        title={deleteText}
                        onConfirm={
                            () => {
                                confirmDelete(record)
                            }
                        }
                        okText="Yes"
                        cancelText="No">
                        <Button id={
                            record.s_id
                        }>Delete</Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];


    useEffect(async () => {
        searchData = await getallStores();
        setOptions(searchData.data)
    }, []);


    const onSearch = (e) => {
        let input = e.toLowerCase();
        if (input.length > 0) {
            console.log(searchData)
            let filteredStoresData = searchData.data.filter((row, index) => {
                if ((row.value).toLowerCase().includes(input)) {
                    return row
                }
            })
            setOptions(filteredStoresData);
        } else {
            setOptions(searchData.data);
        }
    }

    const onSelect = async (e) => {
        console.log(e)
        let coupons = await getStoreCoupons(JSON.stringify({c_store_code: e}))
        setTableData(coupons.data)
    }

    const uploadprops = {
        name: 'file',
        accept: ".csv"

    };

    const uploadfile = async (info) => {
        if (info.file.status != "uploading") {
            var bodyFormData = new FormData();
            bodyFormData.append('list', info.file.originFileObj);
            let result = await bulkCouponUpload(bodyFormData)
            console.log(result)
            if (result.success) {
                if (result.errorList.length > 0) {
                    download(result.errorList)
                } else {
                    message.success("Uploaded Successfully");
                }
            }
        }
    }

    const download = (data) => {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'CouponError' + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
            filename: 'CouponError' + new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

    }

    // displaying table
  // displaying table 
    // displaying table
    return <Container>
        <TopHeader>

            <AutoComplete dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={200}
                options={options}
                style={
                    {width: "500px"}
                }
                onSearch={onSearch}
                onSelect={onSelect}>
                <Input.Search size="large" placeholder="Enter the store name"/>
            </AutoComplete>
            <div>
                <Button onClick={
                    () => {
                        setModalData({});
                        setModal2Visible(true)
                    }
                }>Add Coupon</Button>
                <Upload {...uploadprops}
                    onChange={uploadfile}>
                    <Button icon={<UploadOutlined/>}>Bulk Upload</Button>
                </Upload>
            </div>
        </TopHeader>
        <Table columns={columns}
            dataSource={tableData}
            locale={
                {emptyText: 'Enter Store name to see coupon'}
            }/> {
        modal2Visible && <CouponModel modal2Visible={modal2Visible}
            modalData={modalData}
            setModal2Visible={setModal2Visible}
            setTableData={setTableData}
            searchData={
                searchData.data
            }/>
    } </Container>
}


export default Coupons;
