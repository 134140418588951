import styled from "styled-components";

export const Container = styled.div`
    .ant-modal{
        width:100%;
    }
`;

export const TopHeader = styled.div`
display: flex;
`;
